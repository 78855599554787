/**************************************

Template Name: Xmee | Login and Register Form Html Templates
Template URL: http://html.affixtheme.com/html/xmee
Description: Creative & Exclusive Login Registration Template
Author: AffixTheme
Author URL: https://themeforest.net/user/affixtheme
Version: 1.2 

======================================  
        Table Of Contents
======================================
1. Typography
2. Template Default Style
3. Template Animation
4. Demo Layout One
5. Demo Layout Two
6. Demo Layout Three
7. Demo Layout Four
8. Demo Layout Five
9. Demo Layout Six
10. Demo Layout Seven
11. Demo Layout Eight
12. Demo Layout Nine
13. Demo Layout Ten
14. Demo Layout Eleven
15. Demo Layout Twelve
16. Demo Layout Thirteen
17. Demo Layout Fourteen
18. Demo Layout Fifteen
19. Demo Layout Sixteen
20. Demo Layout Seventeen
21. Demo Layout Eighteen
22. Demo Layout Nineteen
23. Demo Layout Twenty
24. Demo Layout Twenty One
25. Demo Layout Twenty Two
26. Demo Layout Twenty Three
27. Demo Layout Twenty Four


**************************************/
/*=======================================================================
1. Typography
=========================================================================*/
html {
  height: 100%;
}
body {
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  height: 100%;
  line-height: 1.7;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  color: #646464;
  background-color: #fff;
}
p {
  margin: 0 0 60px 0;
  color: #646464;
}
p.texto-rodape {
    color: #9e07c1;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  margin: 0 0 20px 0;
  color: #111;
}
h1,
h2 {
  line-height: 0.2;
}
h3,
h4,
h5,
h6 {
  line-height: 1.4;
}
h1 {
  font-size: 36px;
}
@media only screen and (max-width: 1199px) {
  h1 {
    font-size: 34px;
  }
}
@media only screen and (max-width: 991px) {
  h1 {
    font-size: 32px;
  }
}
@media only screen and (max-width: 767px) {
  h1 {
    font-size: 30px;
  }
}
h2 {
  font-size: 28px;
}
@media only screen and (max-width: 1199px) {
  h2 {
    font-size: 26px;
  }
}
@media only screen and (max-width: 991px) {
  h2 {
    font-size: 24px;
  }
}
@media only screen and (max-width: 767px) {
  h2 {
    font-size: 22px;
  }
}
h3 {
  font-size: 22px;
}
@media only screen and (max-width: 991px) {
  h3 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 767px) {
  h3 {
    font-size: 18px;
  }
}
h4 {
  font-size: 20px;
}
@media only screen and (max-width: 991px) {
  h4 {
    font-size: 18px;
  }
}
@media only screen and (max-width: 767px) {
  h4 {
    font-size: 16px;
  }
}
h5 {
  font-size: 18px;
}
@media only screen and (max-width: 991px) {
  h5 {
    font-size: 16px;
  }
}
/*=======================================================================
2. Template Default Style
=========================================================================*/
a {
  text-decoration: none;
}
a:active,
a:hover,
a:focus {
  text-decoration: none;
}
a:active,
a:hover,
a:focus {
  outline: 0 none;
}
img {
  max-width: 100%;
  height: auto;
}
ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.fxt-content-between {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media only screen and (max-width: 767px) {
  .fxt-none-767 {
    display: none !important;
  }
}
@media only screen and (max-width: 991px) {
  .fxt-none-991 {
    display: none !important;
  }
}
/*========================================================================
3. Template Animation
=========================================================================*/
.fxt-template-animation {
  position: relative;
  z-index: 1;
  width: 100%;
  opacity: 1;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.loaded.fxt-template-animation {
  opacity: 1;
}
.loaded.fxt-template-animation .fxt-transformY-50 {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  -webkit-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}
.loaded.fxt-template-animation .fxt-transition-delay-1 {
  -webkit-transition-delay: 0.1s;
  -o-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.loaded.fxt-template-animation .fxt-transition-delay-2 {
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.loaded.fxt-template-animation .fxt-transition-delay-3 {
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.loaded.fxt-template-animation .fxt-transition-delay-4 {
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}
.loaded.fxt-template-animation .fxt-transition-delay-5 {
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
.loaded.fxt-template-animation .fxt-transition-delay-6 {
  -webkit-transition-delay: 0.6s;
  -o-transition-delay: 0.6s;
  transition-delay: 0.6s;
}
.loaded.fxt-template-animation .fxt-transition-delay-7 {
  -webkit-transition-delay: 0.7s;
  -o-transition-delay: 0.7s;
  transition-delay: 0.7s;
}
.loaded.fxt-template-animation .fxt-transition-delay-8 {
  -webkit-transition-delay: 0.8s;
  -o-transition-delay: 0.8s;
  transition-delay: 0.8s;
}
.loaded.fxt-template-animation .fxt-transition-delay-9 {
  -webkit-transition-delay: 0.9s;
  -o-transition-delay: 0.9s;
  transition-delay: 0.9s;
}
.loaded.fxt-template-animation .fxt-transition-delay-10 {
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}
.loaded.fxt-template-animation .fxt-transition-delay-11 {
  -webkit-transition-delay: 1.1s;
  -o-transition-delay: 1.1s;
  transition-delay: 1.1s;
}
.loaded.fxt-template-animation .fxt-transition-delay-12 {
  -webkit-transition-delay: 1.2s;
  -o-transition-delay: 1.2s;
  transition-delay: 1.2s;
}
.loaded.fxt-template-animation .fxt-transition-delay-13 {
  -webkit-transition-delay: 1.3s;
  -o-transition-delay: 1.3s;
  transition-delay: 1.3s;
}
.loaded.fxt-template-animation .fxt-transition-delay-14 {
  -webkit-transition-delay: 1.4s;
  -o-transition-delay: 1.4s;
  transition-delay: 1.4s;
}
.loaded.fxt-template-animation .fxt-transition-delay-15 {
  -webkit-transition-delay: 1.5s;
  -o-transition-delay: 1.5s;
  transition-delay: 1.5s;
}
.loaded.fxt-template-animation .fxt-transition-delay-16 {
  -webkit-transition-delay: 1.6s;
  -o-transition-delay: 1.6s;
  transition-delay: 1.6s;
}
.loaded.fxt-template-animation .fxt-transition-delay-17 {
  -webkit-transition-delay: 1.7s;
  -o-transition-delay: 1.7s;
  transition-delay: 1.7s;
}
.loaded.fxt-template-animation .fxt-transition-delay-18 {
  -webkit-transition-delay: 1.8s;
  -o-transition-delay: 1.8s;
  transition-delay: 1.8s;
}
.loaded.fxt-template-animation .fxt-transition-delay-19 {
  -webkit-transition-delay: 1.9s;
  -o-transition-delay: 1.9s;
  transition-delay: 1.9s;
}
.loaded.fxt-template-animation .fxt-transition-delay-20 {
  -webkit-transition-delay: 2s;
  -o-transition-delay: 2s;
  transition-delay: 2s;
}
/*========================================================================
4. Demo Layout One
=========================================================================*/
.fxt-template-layout1 .fxt-bg-color {
  background-color: #ffffff;
  min-height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 50px 30px 42px;
}
.fxt-template-layout1 .fxt-bg-img {
  min-height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.fxt-template-layout1 .fxt-content {
  max-width: 450px;
  width: 100%;
}
.fxt-template-layout1 .fxt-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 130px;
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout1 .fxt-header {
    margin-bottom: 100px;
  }
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout1 .fxt-header {
    margin-bottom: 70px;
  }
}
@media only screen and (max-width: 479px) {
  .fxt-template-layout1 .fxt-header {
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 320px) {
  .fxt-template-layout1 .fxt-header {
    display: block;
  }
}
.fxt-template-layout1 .fxt-header .fxt-logo {
  display: block;
  margin-bottom: 30px;
  max-width: 40vw;
}
.fxt-template-layout1 .fxt-header .fxt-page-switcher {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 25px;
}
.fxt-template-layout1 .fxt-header .fxt-page-switcher .switcher-text1 {
  color: #111111;
  font-size: 18px;
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid #9f9f9f;
  line-height: 1;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout1 .fxt-header .fxt-page-switcher .switcher-text1 {
    margin-right: 30px;
  }
}
@media only screen and (max-width: 479px) {
  .fxt-template-layout1 .fxt-header .fxt-page-switcher .switcher-text1 {
    margin-right: 15px;
    padding-right: 10px;
    font-size: 16px;
  }
}
@media only screen and (max-width: 320px) {
  .fxt-template-layout1 .fxt-header .fxt-page-switcher .switcher-text1 {
    margin-right: 10px;
    padding-right: 5px;
  }
}
.fxt-template-layout1 .fxt-header .fxt-page-switcher .switcher-text1:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: 0;
}
.fxt-template-layout1 .fxt-header .fxt-page-switcher .switcher-text1:hover {
  color: #000000;
}
.fxt-template-layout1 .fxt-header .fxt-page-switcher .switcher-text1.active {
  color: #ff0000;
}
.fxt-template-layout1 .fxt-form {
  margin-bottom: 40px;
}
.fxt-template-layout1 .fxt-form h2 {
  font-weight: 700;
  margin-bottom: 5px;
}
.fxt-template-layout1 .fxt-form p {
  margin-bottom: 30px;
  font-size: 17px;
}
.fxt-template-layout1 .fxt-form .form-group {
  position: relative;
  z-index: 1;
}
.fxt-template-layout1 .fxt-form .form-group i {
  position: absolute;
  z-index: 1;
  right: 5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.fxt-template-layout1 .fxt-form .form-group [class^="flaticon-"]:before,
.fxt-template-layout1 .fxt-form .form-group [class*=" flaticon-"]:before,
.fxt-template-layout1 .fxt-form .form-group [class^="flaticon-"]:after,
.fxt-template-layout1 .fxt-form .form-group [class*=" flaticon-"]:after {
  margin-left: 0;
  font-size: 17px;
  color: #a1a1a1;
}
.fxt-template-layout1 .fxt-form .form-control {
  min-height: 40px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-bottom: 1px solid #e7e7e7;
  padding: 10px 30px 10px 0;
  color: #111111;
}
.fxt-template-layout1 .fxt-form input::-webkit-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout1 .fxt-form input::-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout1 .fxt-form input:-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout1 .fxt-form input:-ms-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout1 .fxt-btn-fill {
  margin-top: 15px;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 0;
  color: #fff;
  border-radius: 3px;
  background-color: #ff0000;
  padding: 10px 36px;
  margin-bottom: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout1 .fxt-btn-fill:hover {
  background-color: #da0000;
  border-color: #da0000;
}
.fxt-template-layout1 .fxt-btn-fill:focus {
  outline: none;
}
.fxt-template-layout1 .switcher-text2 {
  color: #9f9f9f;
  font-size: 15px;
  margin-top: 5px;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout1 .switcher-text2:last-child {
  margin-right: 0;
}
.fxt-template-layout1 .switcher-text2:hover {
  color: #666;
}
.fxt-template-layout1 .switcher-text2.active {
  color: #666;
}
.fxt-template-layout1 ul.fxt-socials li {
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 8px;
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout1 ul.fxt-socials li {
    margin-right: 2px;
  }
}
.fxt-template-layout1 ul.fxt-socials li:last-child {
  margin-right: 0;
}
.fxt-template-layout1 ul.fxt-socials li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  height: 40px;
  width: 40px;
  color: #ffffff;
  border-radius: 50%;
  border: 1px solid;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout1 ul.fxt-socials li.fxt-facebook a {
  background-color: #3b5998;
  border-color: #3b5998;
}
.fxt-template-layout1 ul.fxt-socials li.fxt-facebook a:hover {
  background-color: transparent;
  color: #3b5998;
}
.fxt-template-layout1 ul.fxt-socials li.fxt-twitter a {
  background-color: #00acee;
  border-color: #00acee;
}
.fxt-template-layout1 ul.fxt-socials li.fxt-twitter a:hover {
  background-color: transparent;
  color: #00acee;
}
.fxt-template-layout1 ul.fxt-socials li.fxt-google a {
  background-color: #CC3333;
  border-color: #CC3333;
}
.fxt-template-layout1 ul.fxt-socials li.fxt-google a:hover {
  background-color: transparent;
  color: #CC3333;
}
.fxt-template-layout1 ul.fxt-socials li.fxt-instagram a {
  background-color: #3f729b;
  border-color: #3f729b;
}
.fxt-template-layout1 ul.fxt-socials li.fxt-instagram a:hover {
  background-color: transparent;
  color: #3f729b;
}
.fxt-template-layout1 ul.fxt-socials li.fxt-linkedin a {
  background-color: #0077B5;
  border-color: #0077B5;
}
.fxt-template-layout1 ul.fxt-socials li.fxt-linkedin a:hover {
  background-color: transparent;
  color: #0077B5;
}
.fxt-template-layout1 ul.fxt-socials li.fxt-youtube a {
  background-color: #c4302b;
  border-color: #c4302b;
}
.fxt-template-layout1 ul.fxt-socials li.fxt-youtube a:hover {
  background-color: transparent;
  color: #c4302b;
}
.fxt-template-layout1 ul.fxt-socials li.fxt-pinterest a {
  background-color: #bd081c;
  border-color: #bd081c;
}
.fxt-template-layout1 ul.fxt-socials li.fxt-pinterest a:hover {
  background-color: transparent;
  color: #bd081c;
}
.fxt-template-layout1 .fxt-footer {
  text-align: center;
}
/*========================================================================
5. Demo Layout Two
=========================================================================*/
.fxt-template-layout2 {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout2 {
    padding: 15px;
  }
}
.fxt-template-layout2 .fxt-checkbox-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 40px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.fxt-template-layout2 .fxt-bg-color {
  background-color: #ffffff;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
}
.fxt-template-layout2 .fxt-bg-img {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding: 0;
}
.fxt-template-layout2 .fxt-content {
  padding: 100px 65px 70px 80px;
  width: 100%;
  -webkit-box-shadow: 3px 0 79px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 3px 0 79px 0 rgba(0, 0, 0, 0.08);
}
@media only screen and (max-width: 1199px) {
  .fxt-template-layout2 .fxt-content {
    padding: 100px 35px 70px 50px;
  }
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout2 .fxt-content {
    padding: 100px 70px 70px 70px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout2 .fxt-content {
    padding: 80px 50px 50px 50px;
  }
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout2 .fxt-content {
    padding: 60px 30px 30px 30px;
  }
}
@media only screen and (max-width: 479px) {
  .fxt-template-layout2 .fxt-content {
    padding: 50px 20px 20px 20px;
  }
}
.fxt-template-layout2 .fxt-header {
  text-align: center;
}
.fxt-template-layout2 .fxt-logo {
  display: block;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  max-width: 40vw;
}
.fxt-template-layout2 .fxt-form .form-group {
  position: relative;
  z-index: 1;
}
.fxt-template-layout2 .fxt-form .form-control {
  min-height: 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #e7e7e7;
  padding: 10px 15px;
  color: #111111;
}
.fxt-template-layout2 .fxt-form input::-webkit-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout2 .fxt-form input::-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout2 .fxt-form input:-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout2 .fxt-form input:-ms-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout2 .fxt-btn-fill {
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 0;
  color: #fff;
  border-radius: 3px;
  background-color: #ff0000;
  padding: 10px 36px;
  margin-bottom: 10px;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout2 .fxt-btn-fill:hover {
  background-color: #da0000;
  border-color: #da0000;
}
.fxt-template-layout2 .fxt-btn-fill:focus {
  outline: none;
}
.fxt-template-layout2 .switcher-text {
  color: #63bbff;
  font-size: 15px;
  margin-left: 3px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout2 .switcher-text:last-child {
  margin-right: 0;
}
.fxt-template-layout2 .switcher-text:hover {
  color: #666;
}
.fxt-template-layout2 .switcher-text.active {
  color: #666;
}
.fxt-template-layout2 .fxt-style-line {
  overflow: hidden;
  text-align: center;
}
.fxt-template-layout2 .fxt-style-line h2 {
  text-align: center;
  font-weight: 300;
  margin-bottom: 30px;
  font-size: 20px;
  color: #a4a4a4;
  display: inline-block;
  position: relative;
  padding: 0 25px;
  z-index: 1;
}
.fxt-template-layout2 .fxt-style-line h2:before {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #ebebeb;
  left: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.fxt-template-layout2 .fxt-style-line h2:after {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #ebebeb;
  right: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.fxt-template-layout2 ul.fxt-socials {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: 20px;
}
.fxt-template-layout2 ul.fxt-socials li {
  max-width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout2 ul.fxt-socials li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
}
@media only screen and (max-width: 350px) {
  .fxt-template-layout2 ul.fxt-socials li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}
.fxt-template-layout2 ul.fxt-socials li a {
  border-radius: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  height: 45px;
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout2 ul.fxt-socials li a i {
  border-radius: 2px 0 0 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 45px;
  height: 45px;
}
.fxt-template-layout2 ul.fxt-socials li a span {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.fxt-template-layout2 ul.fxt-socials li.fxt-facebook a {
  background-color: #3b5998;
}
.fxt-template-layout2 ul.fxt-socials li.fxt-facebook a i {
  background-color: #4867aa;
}
.fxt-template-layout2 ul.fxt-socials li.fxt-facebook a:hover {
  background-color: #5676bb;
}
.fxt-template-layout2 ul.fxt-socials li.fxt-twitter a {
  background-color: #00acee;
}
.fxt-template-layout2 ul.fxt-socials li.fxt-twitter a i {
  background-color: #33ccff;
}
.fxt-template-layout2 ul.fxt-socials li.fxt-twitter a:hover {
  background-color: #3dc5f3;
}
.fxt-template-layout2 ul.fxt-socials li.fxt-google a {
  background-color: #CC3333;
}
.fxt-template-layout2 ul.fxt-socials li.fxt-google a i {
  background-color: #db4437;
}
.fxt-template-layout2 ul.fxt-socials li.fxt-google a:hover {
  background-color: #e75042;
}
.fxt-template-layout2 .checkbox {
  padding-left: 5px;
}
.fxt-template-layout2 .checkbox label {
  padding-left: 20px;
  color: #a4a4a4;
  margin-bottom: 0;
  font-size: 15px;
  position: relative;
}
.fxt-template-layout2 .checkbox label:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  top: 4px;
  left: 0;
  margin-left: -5px;
  border: 1px solid;
  border-color: #dcdcdc;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.fxt-template-layout2 .checkbox label:after {
  position: absolute;
  margin-left: -20px;
  padding-left: 3px;
  font-size: 10px;
  color: #555555;
}
.fxt-template-layout2 .checkbox input[type="checkbox"] {
  display: none;
}
.fxt-template-layout2 .checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'Font Awesome 5 Free';
  content: "\f00c";
  font-weight: 900;
  color: #ffffff;
  left: 15px;
  top: 4px;
}
.fxt-template-layout2 .checkbox input[type="checkbox"]:checked + label::before {
  background-color: #ff0000;
  border-color: #ff0000;
}
.fxt-template-layout2 .fxt-footer {
  text-align: center;
}
/*========================================================================
6. Demo Layout Three
=========================================================================*/
.fxt-template-layout3 {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  z-index: 1;
  padding: 15px;
}
.fxt-template-layout3:before {
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(4, 4, 14, 0.4);
  left: 0;
  top: 0;
  position: absolute;
  z-index: 0;
}
.fxt-template-layout3 .fxt-checkbox-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 40px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.fxt-template-layout3 .fxt-bg-color {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
}
.fxt-template-layout3 .fxt-bg-img {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding: 0;
}
.fxt-template-layout3 .fxt-content {
  padding: 65px 90px 45px;
  width: 100%;
  background-color: #fff;
}
@media only screen and (max-width: 1199px) {
  .fxt-template-layout3 .fxt-content {
    padding: 65px 70px 45px;
  }
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout3 .fxt-content {
    padding: 50px 30px 30px;
  }
}
.fxt-template-layout3 .fxt-header {
  padding: 30px 30px 30px 0;
}
.fxt-template-layout3 .fxt-header .fxt-logo {
  display: block;
  margin-bottom: 50px;
  max-width: 40vw;
}
.fxt-template-layout3 .fxt-header h1 {
  color: #fff;
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 10px;
}
.fxt-template-layout3 .fxt-header p {
  color: #fff;
}
.fxt-template-layout3 .fxt-form h2 {
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 5px;
}
.fxt-template-layout3 .fxt-form p {
  font-size: 18px;
  color: #111111;
  text-align: center;
}
.fxt-template-layout3 .fxt-form form {
  margin-top: 40px;
}
.fxt-template-layout3 .fxt-form form .input-label {
  color: #979696;
}
.fxt-template-layout3 .fxt-form .form-group {
  position: relative;
  z-index: 1;
}
.fxt-template-layout3 .fxt-form .form-group .field-icon {
  position: absolute;
  z-index: 1;
  right: 24px;
  bottom: 18px;
  padding: 0 5px;
  color: #c5c5c5;
  font-size: 14px;
}
.fxt-template-layout3 .fxt-form .form-group .field-icon:before {
  padding: 17px 10px;
}
.fxt-template-layout3 .fxt-form .form-control {
  min-height: 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #e7e7e7;
  padding: 10px 15px;
  color: #111111;
}
.fxt-template-layout3 .fxt-form input::-webkit-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout3 .fxt-form input::-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout3 .fxt-form input:-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout3 .fxt-form input:-ms-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout3 .fxt-btn-fill {
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 0;
  color: #fff;
  border-radius: 3px;
  background-color: #5a8dee;
  padding: 10px 36px;
  margin-bottom: 10px;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout3 .fxt-btn-fill:hover {
  background-color: #4b7cda;
  border-color: #4b7cda;
}
.fxt-template-layout3 .fxt-btn-fill:focus {
  outline: none;
}
.fxt-template-layout3 .switcher-text {
  color: #55d1ff;
  font-size: 15px;
  margin-left: 3px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout3 .switcher-text:last-child {
  margin-right: 0;
}
.fxt-template-layout3 .switcher-text:hover {
  color: #666;
}
.fxt-template-layout3 .switcher-text.active {
  color: #666;
}
.fxt-template-layout3 .fxt-style-line {
  overflow: hidden;
  text-align: center;
}
.fxt-template-layout3 .fxt-style-line h3 {
  text-align: center;
  font-weight: 300;
  margin-bottom: 30px;
  font-size: 20px;
  color: #a4a4a4;
  display: inline-block;
  position: relative;
  padding: 0 25px;
  z-index: 1;
}
.fxt-template-layout3 .fxt-style-line h3:before {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #ebebeb;
  left: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.fxt-template-layout3 .fxt-style-line h3:after {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #ebebeb;
  right: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.fxt-template-layout3 ul.fxt-socials {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: 20px;
}
.fxt-template-layout3 ul.fxt-socials li {
  display: inline-block;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout3 ul.fxt-socials li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.fxt-template-layout3 ul.fxt-socials li a {
  border-radius: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  height: 45px;
  width: 100%;
  color: #ffffff;
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout3 ul.fxt-socials li a:before {
  content: "";
  width: 0;
  right: 0;
  left: inherit;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout3 ul.fxt-socials li a:hover:before {
  width: 100%;
  left: 0;
  right: inherit;
}
.fxt-template-layout3 ul.fxt-socials li.fxt-facebook a {
  background-color: #3b5998;
}
.fxt-template-layout3 ul.fxt-socials li.fxt-facebook a:before {
  background-color: #5676bb;
}
.fxt-template-layout3 ul.fxt-socials li.fxt-twitter a {
  background-color: #00acee;
}
.fxt-template-layout3 ul.fxt-socials li.fxt-twitter a:before {
  background-color: #3dc5f3;
}
.fxt-template-layout3 ul.fxt-socials li.fxt-google a {
  background-color: #CC3333;
}
.fxt-template-layout3 ul.fxt-socials li.fxt-google a:before {
  background-color: #e75042;
}
.fxt-template-layout3 ul.fxt-socials li.fxt-linkedin a {
  background-color: #0077B5;
}
.fxt-template-layout3 ul.fxt-socials li.fxt-linkedin a:before {
  background-color: #006da6;
}
.fxt-template-layout3 .checkbox {
  padding-left: 5px;
}
.fxt-template-layout3 .checkbox label {
  padding-left: 20px;
  color: #a4a4a4;
  margin-bottom: 0;
  font-size: 15px;
  position: relative;
}
.fxt-template-layout3 .checkbox label:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  top: 4px;
  left: 0;
  margin-left: -5px;
  border: 1px solid;
  border-color: #dcdcdc;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.fxt-template-layout3 .checkbox label:after {
  position: absolute;
  margin-left: -20px;
  padding-left: 3px;
  font-size: 10px;
  color: #555555;
}
.fxt-template-layout3 .checkbox input[type="checkbox"] {
  display: none;
}
.fxt-template-layout3 .checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'Font Awesome 5 Free';
  content: "\f00c";
  font-weight: 900;
  color: #ffffff;
  left: 15px;
  top: 4px;
}
.fxt-template-layout3 .checkbox input[type="checkbox"]:checked + label::before {
  background-color: #5a8dee;
  border-color: #5a8dee;
}
.fxt-template-layout3 .fxt-footer {
  text-align: center;
}
/*========================================================================
7. Demo Layout Four
=========================================================================*/
.fxt-template-layout4 {
  min-height: 100vh;
  position: relative;
  z-index: 1;
}
.fxt-template-layout4:before {
  position: absolute;
  z-index: 0;
  background-color: #01cc66;
  content: "";
  min-height: 100%;
  width: 35vw;
  top: 0;
  left: 0;
}
.fxt-template-layout4 .fxt-bg-wrap {
  padding: 8vh 0 8vh 16vw;
  min-height: 100vh;
}
@media only screen and (max-width: 1199px) {
  .fxt-template-layout4 .fxt-bg-wrap {
    padding: 8vh 0 8vh 10vw;
  }
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout4 .fxt-bg-wrap {
    padding: 6vh 0 6vh 6vw;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout4 .fxt-bg-wrap {
    padding: 5vh 4vw;
  }
}
.fxt-template-layout4 .fxt-checkbox-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.fxt-template-layout4 .fxt-bg-color {
  background-color: #ffffff;
  min-height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 45px;
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout4 .fxt-bg-color {
    padding: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout4 .fxt-bg-color {
    min-height: 100%;
    padding: 20px 30px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
.fxt-template-layout4 .fxt-bg-img {
  padding: 40px 50px;
  min-height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  z-index: 1;
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout4 .fxt-bg-img {
    padding: 30px 20px;
  }
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout4 .fxt-bg-img {
    padding: 30px;
  }
}
.fxt-template-layout4 .fxt-bg-img:before {
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(2, 2, 3, 0.6);
  left: 0;
  top: 0;
  position: absolute;
  z-index: 0;
}
.fxt-template-layout4 .fxt-bg-img:after {
  content: "";
  right: 0;
  top: 20%;
  position: absolute;
  z-index: 1;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-right: 20px solid #fff;
  border-bottom: 15px solid transparent;
}
.fxt-template-layout4 .fxt-header {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  justify-content: center;
  text-align: center;
}
.fxt-template-layout4 .fxt-header .fxt-logo {
  display: block;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  max-width: 40vw;
}
.fxt-template-layout4 .fxt-header h1 {
  color: #fff;
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 10px;
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout4 .fxt-header h1 {
    font-size: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout4 .fxt-header h1 {
    font-size: 28px;
  }
}
.fxt-template-layout4 .fxt-header p {
  color: #fff;
}
.fxt-template-layout4 .fxt-content {
  padding: 30px;
  max-width: 460px;
  width: 100%;
}
@media only screen and (max-width: 1199px) {
  .fxt-template-layout4 .fxt-content {
    padding: 0;
  }
}
.fxt-template-layout4 .fxt-form {
  margin-top: 30px;
}
.fxt-template-layout4 .fxt-form h2 {
  font-weight: 700;
  margin-bottom: 5px;
}
.fxt-template-layout4 .fxt-form p {
  margin-bottom: 30px;
  font-size: 17px;
}
.fxt-template-layout4 .fxt-form .form-group {
  position: relative;
  z-index: 1;
}
.fxt-template-layout4 .fxt-form .form-group .field-icon {
  position: absolute;
  z-index: 1;
  right: 24px;
  bottom: 18px;
  padding: 0 5px;
  color: #c5c5c5;
  font-size: 14px;
}
.fxt-template-layout4 .fxt-form .form-group .field-icon:before {
  padding: 17px 10px;
}
.fxt-template-layout4 .fxt-form .form-control {
  min-height: 40px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-bottom: 1px solid #e7e7e7;
  padding: 10px 30px 10px 0;
  color: #111;
}
.fxt-template-layout4 .fxt-form input::-webkit-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout4 .fxt-form input::-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout4 .fxt-form input:-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout4 .fxt-form input:-ms-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout4 .fxt-btn-fill {
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 0;
  color: #fff;
  border-radius: 3px;
  background-color: #01cc66;
  padding: 10px 36px;
  margin-bottom: 10px;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout4 .fxt-btn-fill:hover {
  background-color: #01b058;
  border-color: #01b058;
}
.fxt-template-layout4 .fxt-btn-fill:focus {
  outline: none;
}
.fxt-template-layout4 ul.fxt-socials {
  text-align: center;
}
.fxt-template-layout4 ul.fxt-socials li {
  display: inline-block;
  margin-right: 4px;
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout4 ul.fxt-socials li {
    margin-right: 2px;
  }
}
.fxt-template-layout4 ul.fxt-socials li:last-child {
  margin-right: 0;
}
.fxt-template-layout4 ul.fxt-socials li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  height: 40px;
  width: 40px;
  color: #ffffff;
  border-radius: 50%;
  border: 1px solid;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout4 ul.fxt-socials li.fxt-facebook a {
  background-color: #3b5998;
  border-color: #3b5998;
}
.fxt-template-layout4 ul.fxt-socials li.fxt-facebook a:hover {
  background-color: transparent;
  color: #3b5998;
}
.fxt-template-layout4 ul.fxt-socials li.fxt-twitter a {
  background-color: #00acee;
  border-color: #00acee;
}
.fxt-template-layout4 ul.fxt-socials li.fxt-twitter a:hover {
  background-color: transparent;
  color: #00acee;
}
.fxt-template-layout4 ul.fxt-socials li.fxt-google a {
  background-color: #CC3333;
  border-color: #CC3333;
}
.fxt-template-layout4 ul.fxt-socials li.fxt-google a:hover {
  background-color: transparent;
  color: #CC3333;
}
.fxt-template-layout4 ul.fxt-socials li.fxt-instagram a {
  background-color: #3f729b;
  border-color: #3f729b;
}
.fxt-template-layout4 ul.fxt-socials li.fxt-instagram a:hover {
  background-color: transparent;
  color: #3f729b;
}
.fxt-template-layout4 ul.fxt-socials li.fxt-linkedin a {
  background-color: #0077B5;
  border-color: #0077B5;
}
.fxt-template-layout4 ul.fxt-socials li.fxt-linkedin a:hover {
  background-color: transparent;
  color: #0077B5;
}
.fxt-template-layout4 ul.fxt-socials li.fxt-youtube a {
  background-color: #c4302b;
  border-color: #c4302b;
}
.fxt-template-layout4 ul.fxt-socials li.fxt-youtube a:hover {
  background-color: transparent;
  color: #c4302b;
}
.fxt-template-layout4 .switcher-text {
  color: #55d1ff;
  font-size: 15px;
  margin-left: 3px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout4 .switcher-text:last-child {
  margin-right: 0;
}
.fxt-template-layout4 .switcher-text:hover {
  color: #666;
}
.fxt-template-layout4 .switcher-text.active {
  color: #666;
}
.fxt-template-layout4 .checkbox {
  padding-left: 5px;
}
.fxt-template-layout4 .checkbox label {
  padding-left: 20px;
  color: #a4a4a4;
  margin-bottom: 0;
  font-size: 15px;
  position: relative;
}
.fxt-template-layout4 .checkbox label:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  top: 4px;
  left: 0;
  margin-left: -5px;
  border: 1px solid;
  border-color: #dcdcdc;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.fxt-template-layout4 .checkbox label:after {
  position: absolute;
  margin-left: -20px;
  padding-left: 3px;
  font-size: 10px;
  color: #555555;
}
.fxt-template-layout4 .checkbox input[type="checkbox"] {
  display: none;
}
.fxt-template-layout4 .checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'Font Awesome 5 Free';
  content: "\f00c";
  font-weight: 900;
  color: #ffffff;
  left: 15px;
  top: 4px;
}
.fxt-template-layout4 .checkbox input[type="checkbox"]:checked + label::before {
  background-color: #01cc66;
  border-color: #01cc66;
}
.fxt-template-layout4 .fxt-footer {
  text-align: center;
}
/*========================================================================
8. Demo Layout Five
=========================================================================*/
.fxt-template-layout5 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.fxt-template-layout5 .fxt-intro {
  max-width: 500px;
  width: 100%;
  padding: 30px;
}
.fxt-template-layout5 .fxt-intro .sub-title {
  font-weight: 300;
  font-size: 40px;
  color: #fff;
  line-height: 1.5;
}
@media only screen and (max-width: 1199px) {
  .fxt-template-layout5 .fxt-intro .sub-title {
    font-size: 30px;
  }
}
.fxt-template-layout5 .fxt-intro h1 {
  font-size: 88px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 25px;
}
@media only screen and (max-width: 1199px) {
  .fxt-template-layout5 .fxt-intro h1 {
    font-size: 60px;
  }
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout5 .fxt-intro h1 {
    font-size: 50px;
  }
}
.fxt-template-layout5 .fxt-intro p {
  color: #fff;
}
.fxt-template-layout5 .fxt-content-between {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.fxt-template-layout5 .fxt-bg-color {
  position: relative;
  max-width: 530px;
  background-color: #ffffff;
  min-height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 50px;
}
.fxt-template-layout5 .fxt-bg-img {
  min-height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.fxt-template-layout5 .fxt-header {
  text-align: center;
}
.fxt-template-layout5 .fxt-form {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-bottom: 40px;
}
.fxt-template-layout5 .fxt-form h2 {
  font-weight: 700;
  margin-bottom: 5px;
}
.fxt-template-layout5 .fxt-form p {
  margin-bottom: 30px;
  font-size: 17px;
}
.fxt-template-layout5 .fxt-form .form-group {
  position: relative;
  z-index: 1;
}
.fxt-template-layout5 .fxt-form .form-group i {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.fxt-template-layout5 .fxt-form .form-group [class^="flaticon-"]:before,
.fxt-template-layout5 .fxt-form .form-group [class*=" flaticon-"]:before,
.fxt-template-layout5 .fxt-form .form-group [class^="flaticon-"]:after,
.fxt-template-layout5 .fxt-form .form-group [class*=" flaticon-"]:after {
  margin-left: 0;
  font-size: 17px;
  color: #a1a1a1;
}
.fxt-template-layout5 .fxt-form .form-control {
  min-height: 40px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-bottom: 1px solid #e7e7e7;
  padding: 10px 10px 10px 30px;
  color: #111111;
}
.fxt-template-layout5 .fxt-form input::-webkit-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout5 .fxt-form input::-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout5 .fxt-form input:-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout5 .fxt-form input:-ms-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout5 .fxt-logo {
  display: block;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  max-width: 40vw;
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout5 .fxt-logo {
    margin-top: 30px;
  }
}
.fxt-template-layout5 .fxt-page-switcher {
  position: absolute;
  left: -97px;
  top: 100px;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 25px;
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout5 .fxt-page-switcher {
    position: inherit;
    left: inherit;
    top: inherit;
    -webkit-box-orient: inherit;
    -webkit-box-direction: inherit;
    -ms-flex-direction: inherit;
    flex-direction: inherit;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.fxt-template-layout5 .fxt-page-switcher .switcher-text {
  color: #fff;
  font-size: 18px;
  padding: 10px 30px 10px 20px;
  border-radius: 45px 0 0 45px;
  max-width: 100px;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout5 .fxt-page-switcher .switcher-text.active {
  color: #000;
  background-color: #fff;
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout5 .fxt-page-switcher .switcher-text.switcher-text1,
  .fxt-template-layout5 .fxt-page-switcher .switcher-text.switcher-text2 {
    background-color: #eaeaea;
    color: #000;
    padding: 6px 30px 6px 20px;
    font-size: 16px;
  }
  .fxt-template-layout5 .fxt-page-switcher .switcher-text.switcher-text1.active,
  .fxt-template-layout5 .fxt-page-switcher .switcher-text.switcher-text2.active {
    color: #0e9d88;
    background-color: #e4e4e4;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout5 .fxt-page-switcher .switcher-text.switcher-text1 {
    border-radius: 45px 0 0 45px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout5 .fxt-page-switcher .switcher-text.switcher-text2 {
    border-radius: 0 45px 45px 0;
  }
}
.fxt-template-layout5 .fxt-btn-fill {
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 0;
  color: #fff;
  border-radius: 45px;
  background-color: #10b099;
  padding: 10px 36px;
  margin-bottom: 10px;
  margin-right: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout5 .fxt-btn-fill:hover {
  background-color: #0e9d88;
  border-color: #0e9d88;
}
.fxt-template-layout5 .fxt-btn-fill:focus {
  outline: none;
}
.fxt-template-layout5 .switcher-text3 {
  position: absolute;
  right: 0;
  top: 0;
  padding: 2px 0;
  color: #9f9f9f;
  font-size: 15px;
  margin-top: 5px;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout5 .switcher-text3:last-child {
  margin-right: 0;
}
.fxt-template-layout5 .switcher-text3:hover {
  color: #666;
}
.fxt-template-layout5 .switcher-text3.active {
  color: #666;
}
.fxt-template-layout5 ul.fxt-socials {
  text-align: center;
  margin-bottom: 30px;
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout5 ul.fxt-socials {
    margin-bottom: 10px;
  }
}
.fxt-template-layout5 ul.fxt-socials li {
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 5px;
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout5 ul.fxt-socials li {
    margin-right: 2px;
  }
}
.fxt-template-layout5 ul.fxt-socials li:last-child {
  margin-right: 0;
}
.fxt-template-layout5 ul.fxt-socials li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  height: 40px;
  width: 40px;
  color: #ffffff;
  border-radius: 50%;
  border: 1px solid;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout5 ul.fxt-socials li.fxt-facebook a {
  background-color: #3b5998;
  border-color: #3b5998;
}
.fxt-template-layout5 ul.fxt-socials li.fxt-facebook a:hover {
  background-color: transparent;
  color: #3b5998;
}
.fxt-template-layout5 ul.fxt-socials li.fxt-twitter a {
  background-color: #00acee;
  border-color: #00acee;
}
.fxt-template-layout5 ul.fxt-socials li.fxt-twitter a:hover {
  background-color: transparent;
  color: #00acee;
}
.fxt-template-layout5 ul.fxt-socials li.fxt-google a {
  background-color: #CC3333;
  border-color: #CC3333;
}
.fxt-template-layout5 ul.fxt-socials li.fxt-google a:hover {
  background-color: transparent;
  color: #CC3333;
}
.fxt-template-layout5 ul.fxt-socials li.fxt-instagram a {
  background-color: #3f729b;
  border-color: #3f729b;
}
.fxt-template-layout5 ul.fxt-socials li.fxt-instagram a:hover {
  background-color: transparent;
  color: #3f729b;
}
.fxt-template-layout5 ul.fxt-socials li.fxt-linkedin a {
  background-color: #0077B5;
  border-color: #0077B5;
}
.fxt-template-layout5 ul.fxt-socials li.fxt-linkedin a:hover {
  background-color: transparent;
  color: #0077B5;
}
.fxt-template-layout5 ul.fxt-socials li.fxt-pinterest a {
  background-color: #bd081c;
  border-color: #bd081c;
}
.fxt-template-layout5 ul.fxt-socials li.fxt-pinterest a:hover {
  background-color: transparent;
  color: #bd081c;
}
.fxt-template-layout5 .checkbox {
  padding-left: 5px;
  margin-bottom: 10px;
}
.fxt-template-layout5 .checkbox label {
  padding-left: 20px;
  color: #a4a4a4;
  margin-bottom: 0;
  font-size: 15px;
  position: relative;
}
.fxt-template-layout5 .checkbox label:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  top: 4px;
  left: 0;
  margin-left: -5px;
  border: 1px solid;
  border-color: #dcdcdc;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.fxt-template-layout5 .checkbox label:after {
  position: absolute;
  margin-left: -20px;
  padding-left: 3px;
  font-size: 10px;
  color: #555555;
}
.fxt-template-layout5 .checkbox input[type="checkbox"] {
  display: none;
}
.fxt-template-layout5 .checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'Font Awesome 5 Free';
  content: "\f00c";
  font-weight: 900;
  color: #ffffff;
  left: 15px;
  top: 4px;
}
.fxt-template-layout5 .checkbox input[type="checkbox"]:checked + label::before {
  background-color: #0e9d88;
  border-color: #0e9d88;
}
.fxt-template-layout5 .fxt-footer {
  text-align: center;
}
/*========================================================================
9. Demo Layout Six
=========================================================================*/
.fxt-template-layout6 {
  padding: 15px;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  z-index: 1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.fxt-template-layout6:before {
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  left: 0;
  top: 0;
  position: absolute;
  z-index: -1;
}
.fxt-template-layout6 .fxt-content-between {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.fxt-template-layout6 .fxt-content {
  max-width: 660px;
  width: 100%;
  background-color: #fff;
  padding: 90px 80px 70px;
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout6 .fxt-content {
    padding: 90px 70px 70px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout6 .fxt-content {
    padding: 70px 50px 50px;
  }
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout6 .fxt-content {
    padding: 50px 30px 30px;
  }
}
.fxt-template-layout6 .fxt-header {
  background-color: transparent;
  margin-bottom: 30px;
}
.fxt-template-layout6 .fxt-header .fxt-logo {
  display: block;
  max-width: 40vw;
}
.fxt-template-layout6 .fxt-form {
  margin-bottom: 40px;
}
.fxt-template-layout6 .fxt-form h2 {
  font-weight: 700;
  margin-bottom: 30px;
}
.fxt-template-layout6 .fxt-form p {
  margin-bottom: 30px;
  font-size: 17px;
}
.fxt-template-layout6 .fxt-form .form-group {
  position: relative;
  z-index: 1;
}
.fxt-template-layout6 .fxt-form .form-group i {
  position: absolute;
  z-index: 1;
  right: 5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.fxt-template-layout6 .fxt-form .form-group [class^="flaticon-"]:before,
.fxt-template-layout6 .fxt-form .form-group [class*=" flaticon-"]:before,
.fxt-template-layout6 .fxt-form .form-group [class^="flaticon-"]:after,
.fxt-template-layout6 .fxt-form .form-group [class*=" flaticon-"]:after {
  margin-left: 0;
  font-size: 17px;
  color: #a1a1a1;
}
.fxt-template-layout6 .fxt-form .form-control {
  background-color: #f6f6f6;
  min-height: 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  padding: 10px 30px;
  color: #111111;
}
.fxt-template-layout6 .fxt-form input::-webkit-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout6 .fxt-form input::-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout6 .fxt-form input:-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout6 .fxt-form input:-ms-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout6 .fxt-btn-fill {
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 0;
  color: #fff;
  border-radius: 3px;
  background-color: #ff5e13;
  padding: 10px 36px;
  margin-bottom: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout6 .fxt-btn-fill:hover {
  background-color: #f0530b;
  border-color: #f0530b;
}
.fxt-template-layout6 .fxt-btn-fill:focus {
  outline: none;
}
.fxt-template-layout6 .switcher-text {
  color: #9f9f9f;
  font-size: 16px;
  margin-top: 5px;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout6 .switcher-text:last-child {
  margin-right: 0;
}
.fxt-template-layout6 .switcher-text:hover {
  color: #666;
}
.fxt-template-layout6 .switcher-text.active {
  color: #666;
}
.fxt-template-layout6 .switcher-text2 {
  color: #353535;
  font-size: 15px;
  margin-top: 5px;
  margin-left: 2px;
  display: inline-block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout6 .switcher-text2:last-child {
  margin-right: 0;
}
.fxt-template-layout6 .switcher-text2:hover {
  color: #000;
}
.fxt-template-layout6 .switcher-text2.active {
  color: #000;
}
.fxt-template-layout6 ul.fxt-socials {
  margin-right: 15px;
  margin-bottom: 15px;
}
.fxt-template-layout6 ul.fxt-socials li {
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout6 ul.fxt-socials li {
    margin-right: 2px;
  }
}
.fxt-template-layout6 ul.fxt-socials li:last-child {
  margin-right: 0;
}
.fxt-template-layout6 ul.fxt-socials li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  height: 40px;
  width: 40px;
  color: #ffffff;
  border-radius: 50%;
  border: 1px solid;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout6 ul.fxt-socials li.fxt-facebook a {
  background-color: #3b5998;
  border-color: #3b5998;
}
.fxt-template-layout6 ul.fxt-socials li.fxt-facebook a:hover {
  background-color: transparent;
  color: #3b5998;
}
.fxt-template-layout6 ul.fxt-socials li.fxt-twitter a {
  background-color: #00acee;
  border-color: #00acee;
}
.fxt-template-layout6 ul.fxt-socials li.fxt-twitter a:hover {
  background-color: transparent;
  color: #00acee;
}
.fxt-template-layout6 ul.fxt-socials li.fxt-google a {
  background-color: #CC3333;
  border-color: #CC3333;
}
.fxt-template-layout6 ul.fxt-socials li.fxt-google a:hover {
  background-color: transparent;
  color: #CC3333;
}
.fxt-template-layout6 ul.fxt-socials li.fxt-instagram a {
  background-color: #3f729b;
  border-color: #3f729b;
}
.fxt-template-layout6 ul.fxt-socials li.fxt-instagram a:hover {
  background-color: transparent;
  color: #3f729b;
}
.fxt-template-layout6 ul.fxt-socials li.fxt-linkedin a {
  background-color: #0077B5;
  border-color: #0077B5;
}
.fxt-template-layout6 ul.fxt-socials li.fxt-linkedin a:hover {
  background-color: transparent;
  color: #0077B5;
}
.fxt-template-layout6 ul.fxt-socials li.fxt-youtube a {
  background-color: #c4302b;
  border-color: #c4302b;
}
.fxt-template-layout6 ul.fxt-socials li.fxt-youtube a:hover {
  background-color: transparent;
  color: #c4302b;
}
.fxt-template-layout6 .fxt-footer {
  background-color: #fff;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.fxt-template-layout6 .fxt-footer p {
  color: #747474;
}
/*========================================================================
10. Demo Layout Seven
=========================================================================*/
.fxt-template-layout7 {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  padding: 15px;
}
.fxt-template-layout7 .fxt-checkbox-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.fxt-template-layout7 .fxt-bg-color {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  -webkit-box-shadow: 0px 0px 62px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 62px 0px rgba(0, 0, 0, 0.07);
}
.fxt-template-layout7 .fxt-bg-img {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding: 0;
}
.fxt-template-layout7 .fxt-content {
  padding: 65px 90px 45px;
  width: 100%;
  background-color: #fff;
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout7 .fxt-content {
    padding: 65px 30px 45px;
  }
}
.fxt-template-layout7 .fxt-header {
  text-align: center;
  margin-bottom: 50px;
}
.fxt-template-layout7 .fxt-header .fxt-logo {
  display: block;
  margin-bottom: 7px;
}
.fxt-template-layout7 .fxt-header h1 {
  color: #fff;
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 10px;
}
.fxt-template-layout7 .fxt-header p {
  color: #999898;
}
.fxt-template-layout7 .fxt-form h2 {
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 5px;
}
.fxt-template-layout7 .fxt-form p {
  font-size: 18px;
  color: #111111;
  text-align: center;
}
.fxt-template-layout7 .fxt-form form .input-label {
  color: #979696;
}
.fxt-template-layout7 .fxt-form .form-group {
  position: relative;
  z-index: 1;
}
.fxt-template-layout7 .fxt-form .form-group .field-icon {
  position: absolute;
  z-index: 1;
  right: 19px;
  bottom: 18px;
  font-size: 14px;
  color: #a1a1a1;
}
.fxt-template-layout7 .fxt-form .form-group .field-icon:before {
  padding: 17px 10px;
}
.fxt-template-layout7 .fxt-form .form-control {
  min-height: 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #e7e7e7;
  padding: 10px 15px;
  color: #111;
}
.fxt-template-layout7 .fxt-form input::-webkit-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout7 .fxt-form input::-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout7 .fxt-form input:-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout7 .fxt-form input:-ms-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout7 .fxt-btn-fill {
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 0;
  color: #fff;
  border-radius: 3px;
  background-color: #2e4bf1;
  padding: 10px 36px;
  margin-bottom: 10px;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout7 .fxt-btn-fill:hover {
  background-color: #4b7cda;
  border-color: #4b7cda;
}
.fxt-template-layout7 .fxt-btn-fill:focus {
  outline: none;
}
.fxt-template-layout7 .switcher-text {
  color: #9f9f9f;
  font-size: 15px;
  margin-top: 5px;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout7 .switcher-text:last-child {
  margin-right: 0;
}
.fxt-template-layout7 .switcher-text:hover {
  color: #666;
}
.fxt-template-layout7 .switcher-text.active {
  color: #666;
}
.fxt-template-layout7 .switcher-text2 {
  color: #353535;
  font-size: 15px;
  margin-top: 5px;
  margin-left: 2px;
  display: inline-block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout7 .switcher-text2:last-child {
  margin-right: 0;
}
.fxt-template-layout7 .switcher-text2:hover {
  color: #000;
}
.fxt-template-layout7 .switcher-text2.active {
  color: #000;
}
.fxt-template-layout7 .fxt-style-line {
  overflow: hidden;
  text-align: center;
}
.fxt-template-layout7 .fxt-style-line h3 {
  text-align: center;
  font-weight: 300;
  margin-bottom: 30px;
  font-size: 20px;
  color: #a4a4a4;
  display: inline-block;
  position: relative;
  padding: 0 25px;
  z-index: 1;
}
.fxt-template-layout7 .fxt-style-line h3:before {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #ebebeb;
  left: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.fxt-template-layout7 .fxt-style-line h3:after {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #ebebeb;
  right: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.fxt-template-layout7 ul.fxt-socials {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: 20px;
}
.fxt-template-layout7 ul.fxt-socials li {
  max-width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout7 ul.fxt-socials li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
}
@media only screen and (max-width: 350px) {
  .fxt-template-layout7 ul.fxt-socials li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}
.fxt-template-layout7 ul.fxt-socials li a {
  border-radius: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  height: 45px;
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout7 ul.fxt-socials li a i {
  border-radius: 2px 0 0 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 45px;
  height: 45px;
}
.fxt-template-layout7 ul.fxt-socials li a span {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.fxt-template-layout7 ul.fxt-socials li.fxt-facebook a {
  background-color: #3b5998;
}
.fxt-template-layout7 ul.fxt-socials li.fxt-facebook a i {
  background-color: #4867aa;
}
.fxt-template-layout7 ul.fxt-socials li.fxt-facebook a:hover {
  background-color: #5676bb;
}
.fxt-template-layout7 ul.fxt-socials li.fxt-twitter a {
  background-color: #00acee;
}
.fxt-template-layout7 ul.fxt-socials li.fxt-twitter a i {
  background-color: #33ccff;
}
.fxt-template-layout7 ul.fxt-socials li.fxt-twitter a:hover {
  background-color: #3dc5f3;
}
.fxt-template-layout7 ul.fxt-socials li.fxt-google a {
  background-color: #CC3333;
}
.fxt-template-layout7 ul.fxt-socials li.fxt-google a i {
  background-color: #db4437;
}
.fxt-template-layout7 ul.fxt-socials li.fxt-google a:hover {
  background-color: #e75042;
}
.fxt-template-layout7 .checkbox {
  padding-left: 5px;
}
.fxt-template-layout7 .checkbox label {
  padding-left: 20px;
  color: #a4a4a4;
  margin-bottom: 0;
  font-size: 15px;
  position: relative;
}
.fxt-template-layout7 .checkbox label:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  top: 4px;
  left: 0;
  margin-left: -5px;
  border: 1px solid;
  border-color: #dcdcdc;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.fxt-template-layout7 .checkbox label:after {
  position: absolute;
  margin-left: -20px;
  padding-left: 3px;
  font-size: 10px;
  color: #555555;
}
.fxt-template-layout7 .checkbox input[type="checkbox"] {
  display: none;
}
.fxt-template-layout7 .checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'Font Awesome 5 Free';
  content: "\f00c";
  font-weight: 900;
  color: #ffffff;
  left: 15px;
  top: 4px;
}
.fxt-template-layout7 .checkbox input[type="checkbox"]:checked + label::before {
  background-color: #2e4bf1;
  border-color: #2e4bf1;
}
.fxt-template-layout7 .fxt-footer {
  text-align: center;
}
.fxt-template-layout7 .fxt-footer p {
  color: #999898;
}
/*========================================================================
11. Demo Layout Eight
=========================================================================*/
.fxt-template-layout8 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  min-height: 100vh;
  z-index: 1;
  padding: 50px 15px 20px;
}
.fxt-template-layout8:before {
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  left: 0;
  top: 0;
  position: absolute;
  z-index: -1;
}
.fxt-template-layout8 .fxt-checkbox-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.fxt-template-layout8 .fxt-content {
  max-width: 460px;
  width: 100%;
}
.fxt-template-layout8 .fxt-header {
  text-align: center;
  margin-bottom: 50px;
}
.fxt-template-layout8 .fxt-logo {
  display: block;
  margin-bottom: 100px;
  margin-left: auto;
  margin-right: auto;
  max-width: 40vw;
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout8 .fxt-logo {
    margin-bottom: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout8 .fxt-logo {
    margin-bottom: 60px;
  }
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout8 .fxt-logo {
    margin-bottom: 40px;
  }
}
.fxt-template-layout8 .fxt-form p {
  font-size: 20px;
  color: #fff;
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout8 .fxt-form p {
    text-align: center;
  }
}
.fxt-template-layout8 .fxt-form .form-group {
  position: relative;
  z-index: 1;
}
.fxt-template-layout8 .fxt-form .form-group .field-icon {
  position: absolute;
  z-index: 1;
  right: 19px;
  bottom: 18px;
  font-size: 14px;
  color: #bebebe;
}
.fxt-template-layout8 .fxt-form .form-group .field-icon:before {
  padding: 17px 10px;
}
.fxt-template-layout8 .fxt-form .form-control {
  min-height: 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #8385bf;
  padding: 10px 15px;
  background-color: transparent;
  color: #fff;
}
.fxt-template-layout8 .fxt-form input::-webkit-input-placeholder {
  color: #bebebe;
  font-size: 18px;
  font-weight: 300;
}
.fxt-template-layout8 .fxt-form input::-moz-placeholder {
  color: #bebebe;
  font-size: 18px;
  font-weight: 300;
}
.fxt-template-layout8 .fxt-form input:-moz-placeholder {
  color: #bebebe;
  font-size: 18px;
  font-weight: 300;
}
.fxt-template-layout8 .fxt-form input:-ms-input-placeholder {
  color: #bebebe;
  font-size: 18px;
  font-weight: 300;
}
.fxt-template-layout8 .fxt-btn-fill {
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 0;
  color: #fff;
  border-radius: 3px;
  background-color: #1fbe66;
  padding: 10px 36px;
  margin-bottom: 10px;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout8 .fxt-btn-fill:hover {
  background-color: #17a156;
  border-color: #17a156;
}
.fxt-template-layout8 .fxt-btn-fill:focus {
  outline: none;
}
.fxt-template-layout8 .switcher-text {
  color: #b6b6b6;
  font-size: 15px;
  margin-top: 5px;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout8 .switcher-text:last-child {
  margin-right: 0;
}
.fxt-template-layout8 .switcher-text:hover {
  color: #e6e6e6;
}
.fxt-template-layout8 .switcher-text2 {
  color: #d4d4d4;
  font-size: 15px;
  margin-top: 5px;
  margin-left: 2px;
  display: inline-block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout8 .switcher-text2:last-child {
  margin-right: 0;
}
.fxt-template-layout8 .switcher-text2:hover {
  color: #e6e6e6;
}
.fxt-template-layout8 .fxt-style-line {
  overflow: hidden;
  text-align: center;
}
.fxt-template-layout8 .fxt-style-line h3 {
  text-align: center;
  font-weight: 300;
  margin-bottom: 30px;
  font-size: 20px;
  color: #a4a4a4;
  display: inline-block;
  position: relative;
  padding: 0 25px;
  z-index: 1;
}
.fxt-template-layout8 .fxt-style-line h3:before {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #a4a4a4;
  left: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.fxt-template-layout8 .fxt-style-line h3:after {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #a4a4a4;
  right: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.fxt-template-layout8 ul.fxt-socials {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: 20px;
}
.fxt-template-layout8 ul.fxt-socials li {
  max-width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout8 ul.fxt-socials li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
}
@media only screen and (max-width: 350px) {
  .fxt-template-layout8 ul.fxt-socials li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}
.fxt-template-layout8 ul.fxt-socials li a {
  border-radius: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  height: 45px;
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout8 ul.fxt-socials li a i {
  border-radius: 2px 0 0 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 45px;
  height: 45px;
}
.fxt-template-layout8 ul.fxt-socials li a span {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.fxt-template-layout8 ul.fxt-socials li.fxt-facebook a {
  background-color: #3b5998;
}
.fxt-template-layout8 ul.fxt-socials li.fxt-facebook a i {
  background-color: #4867aa;
}
.fxt-template-layout8 ul.fxt-socials li.fxt-facebook a:hover {
  background-color: #5676bb;
}
.fxt-template-layout8 ul.fxt-socials li.fxt-twitter a {
  background-color: #00acee;
}
.fxt-template-layout8 ul.fxt-socials li.fxt-twitter a i {
  background-color: #33ccff;
}
.fxt-template-layout8 ul.fxt-socials li.fxt-twitter a:hover {
  background-color: #3dc5f3;
}
.fxt-template-layout8 ul.fxt-socials li.fxt-google a {
  background-color: #CC3333;
}
.fxt-template-layout8 ul.fxt-socials li.fxt-google a i {
  background-color: #db4437;
}
.fxt-template-layout8 ul.fxt-socials li.fxt-google a:hover {
  background-color: #e75042;
}
.fxt-template-layout8 .checkbox {
  padding-left: 5px;
  margin-right: 30px;
}
.fxt-template-layout8 .checkbox label {
  padding-left: 20px;
  color: #b9b9b9;
  margin-bottom: 0;
  font-size: 15px;
  position: relative;
}
.fxt-template-layout8 .checkbox label:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  top: 4px;
  left: 0;
  margin-left: -5px;
  border: 1px solid;
  border-color: #dcdcdc;
  border-radius: 2px;
  background-color: transparent;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.fxt-template-layout8 .checkbox label:after {
  position: absolute;
  margin-left: -20px;
  padding-left: 3px;
  font-size: 10px;
  color: #555555;
}
.fxt-template-layout8 .checkbox input[type="checkbox"] {
  display: none;
}
.fxt-template-layout8 .checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'Font Awesome 5 Free';
  content: "\f00c";
  font-weight: 900;
  color: #ffffff;
  left: 15px;
  top: 4px;
}
.fxt-template-layout8 .checkbox input[type="checkbox"]:checked + label::before {
  background-color: #1fbe66;
  border-color: #1fbe66;
}
.fxt-template-layout8 .fxt-footer {
  text-align: center;
}
.fxt-template-layout8 .fxt-footer p {
  color: #b6b6b6;
}
/*========================================================================
12. Demo Layout Nine
=========================================================================*/
.fxt-template-layout9 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  position: relative;
  min-height: 100vh;
  z-index: 1;
  padding: 50px 0 20px;
}
.fxt-template-layout9:before {
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  left: 0;
  top: 0;
  position: absolute;
  z-index: -1;
}
.fxt-template-layout9 .fxt-checkbox-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.fxt-template-layout9 .fxt-content {
  padding-top: 40px;
  padding-bottom: 15px;
  padding-left: 70px;
  margin-left: 40px;
  border-left: 2px solid rgba(111, 117, 182, 0.5);
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout9 .fxt-content {
    padding-left: 0;
    margin-left: 0;
    margin-top: 40px;
    border-left: 0;
    padding-bottom: 0;
    border-top: 2px solid rgba(111, 117, 182, 0.5);
  }
}
.fxt-template-layout9 .fxt-content h2 {
  font-size: 20px;
  color: #fff;
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout9 .fxt-content h2 {
    text-align: center;
  }
}
.fxt-template-layout9 .fxt-header {
  text-align: center;
}
.fxt-template-layout9 .fxt-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 40vw;
}
.fxt-template-layout9 .fxt-form .form-group {
  position: relative;
  z-index: 1;
}
.fxt-template-layout9 .fxt-form .form-group .field-icon {
  position: absolute;
  z-index: 1;
  right: 19px;
  bottom: 18px;
  font-size: 14px;
  color: #bebebe;
}
.fxt-template-layout9 .fxt-form .form-group .field-icon:before {
  padding: 17px 10px;
}
.fxt-template-layout9 .fxt-form .form-control {
  min-height: 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #8385bf;
  padding: 10px 15px;
  background-color: transparent;
  color: #fff;
}
.fxt-template-layout9 .fxt-form input::-webkit-input-placeholder {
  color: #bebebe;
  font-size: 18px;
  font-weight: 300;
}
.fxt-template-layout9 .fxt-form input::-moz-placeholder {
  color: #bebebe;
  font-size: 18px;
  font-weight: 300;
}
.fxt-template-layout9 .fxt-form input:-moz-placeholder {
  color: #bebebe;
  font-size: 18px;
  font-weight: 300;
}
.fxt-template-layout9 .fxt-form input:-ms-input-placeholder {
  color: #bebebe;
  font-size: 18px;
  font-weight: 300;
}
.fxt-template-layout9 .fxt-btn-fill {
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 0;
  color: #fff;
  border-radius: 3px;
  background-color: #ffbd3f;
  padding: 10px 36px;
  margin-bottom: 10px;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout9 .fxt-btn-fill:hover {
  background-color: #db9e2d;
  border-color: #db9e2d;
}
.fxt-template-layout9 .fxt-btn-fill:focus {
  outline: none;
}
.fxt-template-layout9 .switcher-text {
  color: #b6b6b6;
  font-size: 15px;
  margin-top: 5px;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout9 .switcher-text:last-child {
  margin-right: 0;
}
.fxt-template-layout9 .switcher-text:hover {
  color: #e6e6e6;
}
.fxt-template-layout9 .switcher-text2 {
  color: #d4d4d4;
  font-size: 15px;
  margin-top: 5px;
  margin-left: 2px;
  display: inline-block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout9 .switcher-text2:last-child {
  margin-right: 0;
}
.fxt-template-layout9 .switcher-text2:hover {
  color: #e6e6e6;
}
.fxt-template-layout9 .checkbox {
  padding-left: 5px;
  margin-right: 10px;
}
.fxt-template-layout9 .checkbox label {
  padding-left: 20px;
  color: #b9b9b9;
  margin-bottom: 0;
  font-size: 15px;
  position: relative;
}
.fxt-template-layout9 .checkbox label:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  top: 4px;
  left: 0;
  margin-left: -5px;
  border: 1px solid;
  border-color: #dcdcdc;
  border-radius: 2px;
  background-color: transparent;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.fxt-template-layout9 .checkbox label:after {
  position: absolute;
  margin-left: -20px;
  padding-left: 3px;
  font-size: 10px;
  color: #555555;
}
.fxt-template-layout9 .checkbox input[type="checkbox"] {
  display: none;
}
.fxt-template-layout9 .checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'Font Awesome 5 Free';
  content: "\f00c";
  font-weight: 900;
  color: #ffffff;
  left: 15px;
  top: 4px;
}
.fxt-template-layout9 .checkbox input[type="checkbox"]:checked + label::before {
  background-color: #ffbd3f;
  border-color: #ffbd3f;
}
.fxt-template-layout9 .fxt-footer {
  text-align: center;
}
.fxt-template-layout9 .fxt-footer p {
  color: #b6b6b6;
}
/*========================================================================
13. Demo Layout Ten
=========================================================================*/
.fxt-template-layout10 .fxt-checkbox-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.fxt-template-layout10 .fxt-bg-color {
  background-color: #ffffff;
  min-height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 60px 30px;
}
.fxt-template-layout10 .fxt-bg-img {
  min-height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 30px 15px;
}
.fxt-template-layout10 .fxt-content {
  max-width: 450px;
  width: 100%;
}
.fxt-template-layout10 .fxt-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 480px;
  width: 100%;
  text-align: center;
}
.fxt-template-layout10 .fxt-header .fxt-logo {
  display: block;
  margin-bottom: 30px;
  max-width: 40vw;
}
.fxt-template-layout10 .fxt-header h1 {
  color: #fff;
}
.fxt-template-layout10 .fxt-header p {
  color: #fff;
}
.fxt-template-layout10 .fxt-form {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-bottom: 40px;
}
.fxt-template-layout10 .fxt-form h2 {
  font-weight: 500;
  margin-bottom: 80px;
  font-size: 24px;
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout10 .fxt-form h2 {
    font-size: 22px;
    margin-bottom: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout10 .fxt-form h2 {
    font-size: 20px;
    margin-bottom: 40px;
  }
}
.fxt-template-layout10 .fxt-form .form-group {
  position: relative;
  z-index: 1;
}
.fxt-template-layout10 .fxt-form .form-group .field-icon {
  position: absolute;
  z-index: 1;
  right: 8px;
  top: 50%;
  color: #9f9f9f;
  font-size: 14px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.fxt-template-layout10 .fxt-form .form-group .field-icon:before {
  padding: 12px 0 12px 10px;
}
.fxt-template-layout10 .fxt-form .form-control {
  min-height: 40px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-bottom: 1px solid #e7e7e7;
  padding: 10px 30px 10px 0;
  color: #111;
}
.fxt-template-layout10 .fxt-form input::-webkit-input-placeholder {
  color: #999999;
  font-size: 18px;
  font-weight: 300;
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout10 .fxt-form input::-webkit-input-placeholder {
    font-size: 17px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout10 .fxt-form input::-webkit-input-placeholder {
    font-size: 16px;
  }
}
.fxt-template-layout10 .fxt-form input::-moz-placeholder {
  color: #999999;
  font-size: 18px;
  font-weight: 300;
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout10 .fxt-form input::-moz-placeholder {
    font-size: 17px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout10 .fxt-form input::-moz-placeholder {
    font-size: 16px;
  }
}
.fxt-template-layout10 .fxt-form input:-moz-placeholder {
  color: #999999;
  font-size: 18px;
  font-weight: 300;
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout10 .fxt-form input:-moz-placeholder {
    font-size: 17px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout10 .fxt-form input:-moz-placeholder {
    font-size: 16px;
  }
}
.fxt-template-layout10 .fxt-form input:-ms-input-placeholder {
  color: #999999;
  font-size: 18px;
  font-weight: 300;
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout10 .fxt-form input:-ms-input-placeholder {
    font-size: 17px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout10 .fxt-form input:-ms-input-placeholder {
    font-size: 16px;
  }
}
.fxt-template-layout10 .fxt-btn-fill {
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 0;
  color: #fff;
  border-radius: 3px;
  background-color: #ffbd3f;
  padding: 10px 36px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout10 .fxt-btn-fill:hover {
  background-color: #eba627;
}
.fxt-template-layout10 .fxt-btn-fill:focus {
  outline: none;
}
.fxt-template-layout10 .fxt-btn-ghost {
  margin-top: 15px;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 3px;
  background-color: transparent;
  padding: 10px 36px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout10 .fxt-btn-ghost:hover {
  background-color: #fff;
  border-color: #fff;
  color: #ffbd3f;
}
.fxt-template-layout10 .fxt-btn-ghost:focus {
  outline: none;
}
.fxt-template-layout10 .switcher-text2 {
  color: #9f9f9f;
  font-size: 15px;
  margin-top: 5px;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout10 .switcher-text2:last-child {
  margin-right: 0;
}
.fxt-template-layout10 .switcher-text2:hover {
  color: #666;
}
.fxt-template-layout10 .switcher-text2.active {
  color: #666;
}
.fxt-template-layout10 .checkbox {
  padding-left: 5px;
  margin-right: 10px;
}
.fxt-template-layout10 .checkbox label {
  padding-left: 20px;
  color: #9f9f9f;
  margin-bottom: 0;
  font-size: 15px;
  position: relative;
}
.fxt-template-layout10 .checkbox label:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  top: 4px;
  left: 0;
  margin-left: -5px;
  border: 1px solid;
  border-color: #dcdcdc;
  border-radius: 2px;
  background-color: transparent;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.fxt-template-layout10 .checkbox label:after {
  position: absolute;
  margin-left: -20px;
  padding-left: 3px;
  font-size: 10px;
  color: #555555;
}
.fxt-template-layout10 .checkbox input[type="checkbox"] {
  display: none;
}
.fxt-template-layout10 .checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'Font Awesome 5 Free';
  content: "\f00c";
  font-weight: 900;
  color: #ffffff;
  left: 15px;
  top: 4px;
}
.fxt-template-layout10 .checkbox input[type="checkbox"]:checked + label::before {
  background-color: #ffbd3f;
  border-color: #ffbd3f;
}
.fxt-template-layout10 ul.fxt-socials li {
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 5px;
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout10 ul.fxt-socials li {
    margin-right: 2px;
  }
}
.fxt-template-layout10 ul.fxt-socials li:last-child {
  margin-right: 0;
}
.fxt-template-layout10 ul.fxt-socials li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid;
  border-color: #dadada;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout10 ul.fxt-socials li.fxt-facebook a {
  color: #3b5998;
}
.fxt-template-layout10 ul.fxt-socials li.fxt-facebook a:hover {
  border-color: #3b5998;
  background-color: #3b5998;
  color: #fff;
}
.fxt-template-layout10 ul.fxt-socials li.fxt-twitter a {
  color: #00acee;
}
.fxt-template-layout10 ul.fxt-socials li.fxt-twitter a:hover {
  border-color: #00acee;
  background-color: #00acee;
  color: #fff;
}
.fxt-template-layout10 ul.fxt-socials li.fxt-google a {
  color: #CC3333;
}
.fxt-template-layout10 ul.fxt-socials li.fxt-google a:hover {
  border-color: #CC3333;
  background-color: #CC3333;
  color: #fff;
}
.fxt-template-layout10 ul.fxt-socials li.fxt-instagram a {
  color: #3f729b;
}
.fxt-template-layout10 ul.fxt-socials li.fxt-instagram a:hover {
  border-color: #3f729b;
  background-color: #3f729b;
  color: #fff;
}
.fxt-template-layout10 ul.fxt-socials li.fxt-linkedin a {
  color: #0077B5;
}
.fxt-template-layout10 ul.fxt-socials li.fxt-linkedin a:hover {
  border-color: #0077B5;
  background-color: #0077B5;
  color: #fff;
}
.fxt-template-layout10 ul.fxt-socials li.fxt-youtube a {
  color: #c4302b;
}
.fxt-template-layout10 ul.fxt-socials li.fxt-youtube a:hover {
  border-color: #c4302b;
  background-color: #c4302b;
  color: #fff;
}
.fxt-template-layout10 ul.fxt-socials li.fxt-pinterest a {
  color: #bd081c;
}
.fxt-template-layout10 ul.fxt-socials li.fxt-pinterest a:hover {
  border-color: #bd081c;
  background-color: #bd081c;
  color: #fff;
}
.fxt-template-layout10 .fxt-footer {
  margin-top: 50px;
  text-align: center;
}
/*========================================================================
14. Demo Layout Eleven
=========================================================================*/
.fxt-template-layout11 {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  background-color: #fff;
  padding: 15px;
}
.fxt-template-layout11 .fxt-checkbox-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.fxt-template-layout11 .fxt-bg-color {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  -webkit-box-shadow: 0px 0px 62px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 62px 0px rgba(0, 0, 0, 0.07);
}
.fxt-template-layout11 .fxt-bg-img {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding: 0;
}
.fxt-template-layout11 .fxt-content {
  padding: 65px 90px 45px;
  width: 100%;
  background-color: #fff;
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout11 .fxt-content {
    padding: 65px 30px 45px;
  }
}
.fxt-template-layout11 .fxt-header {
  text-align: center;
  margin-bottom: 50px;
}
.fxt-template-layout11 .fxt-header .fxt-logo {
  display: block;
  margin-bottom: 7px;
}
.fxt-template-layout11 .fxt-header h1 {
  color: #fff;
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 10px;
}
.fxt-template-layout11 .fxt-header p {
  color: #999898;
}
.fxt-template-layout11 .fxt-form h2 {
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 5px;
}
.fxt-template-layout11 .fxt-form p {
  font-size: 18px;
  color: #111111;
  text-align: center;
}
.fxt-template-layout11 .fxt-form form .input-label {
  color: #979696;
}
.fxt-template-layout11 .fxt-form .form-group {
  position: relative;
  z-index: 1;
}
.fxt-template-layout11 .fxt-form .form-group .field-icon {
  position: absolute;
  z-index: 1;
  right: 19px;
  bottom: 18px;
  font-size: 14px;
  color: #a1a1a1;
}
.fxt-template-layout11 .fxt-form .form-group .field-icon:before {
  padding: 17px 10px;
}
.fxt-template-layout11 .fxt-form .form-control {
  min-height: 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #e7e7e7;
  padding: 10px 15px;
  color: #111;
}
.fxt-template-layout11 .fxt-form input::-webkit-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout11 .fxt-form input::-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout11 .fxt-form input:-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout11 .fxt-form input:-ms-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout11 .fxt-btn-fill {
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 0;
  color: #fff;
  border-radius: 3px;
  background-color: #111;
  padding: 10px 36px;
  margin-bottom: 10px;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout11 .fxt-btn-fill:hover {
  background-color: #333;
  border-color: #333;
}
.fxt-template-layout11 .fxt-btn-fill:focus {
  outline: none;
}
.fxt-template-layout11 .switcher-text {
  color: #9f9f9f;
  font-size: 15px;
  margin-top: 5px;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout11 .switcher-text:last-child {
  margin-right: 0;
}
.fxt-template-layout11 .switcher-text:hover {
  color: #666;
}
.fxt-template-layout11 .switcher-text.active {
  color: #666;
}
.fxt-template-layout11 .switcher-text2 {
  color: #353535;
  font-size: 15px;
  margin-top: 5px;
  margin-left: 2px;
  display: inline-block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout11 .switcher-text2:last-child {
  margin-right: 0;
}
.fxt-template-layout11 .switcher-text2:hover {
  color: #000;
}
.fxt-template-layout11 .switcher-text2.active {
  color: #000;
}
.fxt-template-layout11 .fxt-style-line {
  overflow: hidden;
  text-align: center;
}
.fxt-template-layout11 .fxt-style-line h3 {
  text-align: center;
  font-weight: 300;
  margin-bottom: 30px;
  font-size: 20px;
  color: #a4a4a4;
  display: inline-block;
  position: relative;
  padding: 0 25px;
  z-index: 1;
}
.fxt-template-layout11 .fxt-style-line h3:before {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #ebebeb;
  left: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.fxt-template-layout11 .fxt-style-line h3:after {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #ebebeb;
  right: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.fxt-template-layout11 ul.fxt-socials {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: 20px;
}
.fxt-template-layout11 ul.fxt-socials li {
  max-width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout11 ul.fxt-socials li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
}
@media only screen and (max-width: 350px) {
  .fxt-template-layout11 ul.fxt-socials li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}
.fxt-template-layout11 ul.fxt-socials li a {
  border-radius: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  height: 45px;
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout11 ul.fxt-socials li a i {
  border-radius: 2px 0 0 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 45px;
  height: 45px;
}
.fxt-template-layout11 ul.fxt-socials li a span {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.fxt-template-layout11 ul.fxt-socials li.fxt-facebook a {
  background-color: #3b5998;
}
.fxt-template-layout11 ul.fxt-socials li.fxt-facebook a i {
  background-color: #4867aa;
}
.fxt-template-layout11 ul.fxt-socials li.fxt-facebook a:hover {
  background-color: #5676bb;
}
.fxt-template-layout11 ul.fxt-socials li.fxt-twitter a {
  background-color: #00acee;
}
.fxt-template-layout11 ul.fxt-socials li.fxt-twitter a i {
  background-color: #33ccff;
}
.fxt-template-layout11 ul.fxt-socials li.fxt-twitter a:hover {
  background-color: #3dc5f3;
}
.fxt-template-layout11 ul.fxt-socials li.fxt-google a {
  background-color: #CC3333;
}
.fxt-template-layout11 ul.fxt-socials li.fxt-google a i {
  background-color: #db4437;
}
.fxt-template-layout11 ul.fxt-socials li.fxt-google a:hover {
  background-color: #e75042;
}
.fxt-template-layout11 .checkbox {
  padding-left: 5px;
}
.fxt-template-layout11 .checkbox label {
  padding-left: 20px;
  color: #a4a4a4;
  margin-bottom: 0;
  font-size: 15px;
  position: relative;
}
.fxt-template-layout11 .checkbox label:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  top: 4px;
  left: 0;
  margin-left: -5px;
  border: 1px solid;
  border-color: #dcdcdc;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.fxt-template-layout11 .checkbox label:after {
  position: absolute;
  margin-left: -20px;
  padding-left: 3px;
  font-size: 10px;
  color: #555555;
}
.fxt-template-layout11 .checkbox input[type="checkbox"] {
  display: none;
}
.fxt-template-layout11 .checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'Font Awesome 5 Free';
  content: "\f00c";
  font-weight: 900;
  color: #ffffff;
  left: 15px;
  top: 4px;
}
.fxt-template-layout11 .checkbox input[type="checkbox"]:checked + label::before {
  background-color: #111;
  border-color: #111;
}
.fxt-template-layout11 .fxt-footer {
  text-align: center;
}
.fxt-template-layout11 .fxt-footer p {
  color: #999898;
}
/*========================================================================
15. Demo Layout Twelve
=========================================================================*/
.fxt-template-layout12 {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 0 15px;
}
.fxt-template-layout12 .fxt-checkbox-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.fxt-template-layout12 .fxt-bg-color {
  min-height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  padding: 0;
}
.fxt-template-layout12 .fxt-content {
  padding: 65px 90px 45px;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout12 .fxt-content {
    padding: 65px 30px 45px;
  }
}
.fxt-template-layout12 .fxt-header {
  text-align: center;
  margin-bottom: 50px;
}
.fxt-template-layout12 .fxt-header .fxt-logo {
  display: block;
  margin-bottom: 7px;
  max-width: 40vw;
  margin-left: auto;
  margin-right: auto;
}
.fxt-template-layout12 .fxt-header h1 {
  color: #fff;
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 10px;
}
.fxt-template-layout12 .fxt-header p {
  color: #999898;
}
.fxt-template-layout12 .fxt-form h2 {
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 5px;
}
.fxt-template-layout12 .fxt-form p {
  font-size: 18px;
  color: #111111;
  text-align: center;
}
.fxt-template-layout12 .fxt-form form .input-label {
  color: #979696;
}
.fxt-template-layout12 .fxt-form .form-group {
  position: relative;
  z-index: 1;
}
.fxt-template-layout12 .fxt-form .form-group .field-icon {
  position: absolute;
  z-index: 1;
  right: 19px;
  bottom: 18px;
  font-size: 14px;
  color: #a1a1a1;
}
.fxt-template-layout12 .fxt-form .form-group .field-icon:before {
  padding: 17px 10px;
}
.fxt-template-layout12 .fxt-form .form-control {
  min-height: 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #e7e7e7;
  padding: 10px 15px;
  color: #111;
}
.fxt-template-layout12 .fxt-form input::-webkit-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout12 .fxt-form input::-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout12 .fxt-form input:-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout12 .fxt-form input:-ms-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout12 .fxt-btn-fill {
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 0;
  color: #fff;
  border-radius: 3px;
  background-color: #2e4bf1;
  padding: 10px 36px;
  margin-bottom: 10px;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout12 .fxt-btn-fill:hover {
  background-color: #4b7cda;
  border-color: #4b7cda;
}
.fxt-template-layout12 .fxt-btn-fill:focus {
  outline: none;
}
.fxt-template-layout12 .switcher-text {
  color: #9f9f9f;
  font-size: 15px;
  margin-top: 5px;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout12 .switcher-text:last-child {
  margin-right: 0;
}
.fxt-template-layout12 .switcher-text:hover {
  color: #666;
}
.fxt-template-layout12 .switcher-text.active {
  color: #666;
}
.fxt-template-layout12 .switcher-text2 {
  color: #353535;
  font-size: 15px;
  margin-top: 5px;
  margin-left: 2px;
  display: inline-block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout12 .switcher-text2:last-child {
  margin-right: 0;
}
.fxt-template-layout12 .switcher-text2:hover {
  color: #000;
}
.fxt-template-layout12 .switcher-text2.active {
  color: #000;
}
.fxt-template-layout12 .fxt-style-line {
  overflow: hidden;
  text-align: center;
}
.fxt-template-layout12 .fxt-style-line h3 {
  text-align: center;
  font-weight: 300;
  margin-bottom: 30px;
  font-size: 20px;
  color: #a4a4a4;
  display: inline-block;
  position: relative;
  padding: 0 25px;
  z-index: 1;
}
.fxt-template-layout12 .fxt-style-line h3:before {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #ebebeb;
  left: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.fxt-template-layout12 .fxt-style-line h3:after {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #ebebeb;
  right: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.fxt-template-layout12 ul.fxt-socials {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: 20px;
}
.fxt-template-layout12 ul.fxt-socials li {
  max-width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout12 ul.fxt-socials li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
}
@media only screen and (max-width: 350px) {
  .fxt-template-layout12 ul.fxt-socials li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}
.fxt-template-layout12 ul.fxt-socials li a {
  border-radius: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  height: 45px;
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout12 ul.fxt-socials li a i {
  border-radius: 2px 0 0 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 45px;
  height: 45px;
}
.fxt-template-layout12 ul.fxt-socials li a span {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.fxt-template-layout12 ul.fxt-socials li.fxt-facebook a {
  background-color: #3b5998;
}
.fxt-template-layout12 ul.fxt-socials li.fxt-facebook a i {
  background-color: #4867aa;
}
.fxt-template-layout12 ul.fxt-socials li.fxt-facebook a:hover {
  background-color: #5676bb;
}
.fxt-template-layout12 ul.fxt-socials li.fxt-twitter a {
  background-color: #00acee;
}
.fxt-template-layout12 ul.fxt-socials li.fxt-twitter a i {
  background-color: #33ccff;
}
.fxt-template-layout12 ul.fxt-socials li.fxt-twitter a:hover {
  background-color: #3dc5f3;
}
.fxt-template-layout12 ul.fxt-socials li.fxt-google a {
  background-color: #CC3333;
}
.fxt-template-layout12 ul.fxt-socials li.fxt-google a i {
  background-color: #db4437;
}
.fxt-template-layout12 ul.fxt-socials li.fxt-google a:hover {
  background-color: #e75042;
}
.fxt-template-layout12 .checkbox {
  padding-left: 5px;
}
.fxt-template-layout12 .checkbox label {
  padding-left: 20px;
  color: #a4a4a4;
  margin-bottom: 0;
  font-size: 15px;
  position: relative;
}
.fxt-template-layout12 .checkbox label:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  top: 4px;
  left: 0;
  margin-left: -5px;
  border: 1px solid;
  border-color: #dcdcdc;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.fxt-template-layout12 .checkbox label:after {
  position: absolute;
  margin-left: -20px;
  padding-left: 3px;
  font-size: 10px;
  color: #555555;
}
.fxt-template-layout12 .checkbox input[type="checkbox"] {
  display: none;
}
.fxt-template-layout12 .checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'Font Awesome 5 Free';
  content: "\f00c";
  font-weight: 900;
  color: #ffffff;
  left: 15px;
  top: 4px;
}
.fxt-template-layout12 .checkbox input[type="checkbox"]:checked + label::before {
  background-color: #2e4bf1;
  border-color: #2e4bf1;
}
.fxt-template-layout12 .fxt-footer {
  text-align: center;
}
.fxt-template-layout12 .fxt-footer p {
  color: #999898;
}
/*========================================================================
16. Demo Layout Thirteen
=========================================================================*/
.fxt-template-layout13 {
  min-height: 100vh;
  position: relative;
  z-index: 1;
}
.fxt-template-layout13:before {
  position: absolute;
  z-index: 0;
  background-color: #009BB6;
  content: "";
  min-height: 100%;
  width: 35vw;
  top: 0;
  right: 0;
}
.fxt-template-layout13 .fxt-bg-wrap {
  padding: 8vh 16vw 8vh 0;
  min-height: 100vh;
}
@media only screen and (max-width: 1199px) {
  .fxt-template-layout13 .fxt-bg-wrap {
    padding: 8vh 10vw 8vh 0;
  }
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout13 .fxt-bg-wrap {
    padding: 6vh 6vw 6vh 0;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout13 .fxt-bg-wrap {
    padding: 5vh 4vw;
  }
}
.fxt-template-layout13 .fxt-checkbox-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.fxt-template-layout13 .fxt-bg-color {
  background-color: #ffffff;
  min-height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 45px;
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout13 .fxt-bg-color {
    padding: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout13 .fxt-bg-color {
    min-height: 100%;
    padding: 20px 30px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
.fxt-template-layout13 .fxt-bg-img {
  padding: 40px 50px;
  min-height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  z-index: 1;
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout13 .fxt-bg-img {
    padding: 30px 20px;
  }
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout13 .fxt-bg-img {
    padding: 30px;
  }
}
.fxt-template-layout13 .fxt-bg-img:before {
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(2, 2, 3, 0.6);
  right: 0;
  top: 0;
  position: absolute;
  z-index: 0;
}
.fxt-template-layout13 .fxt-bg-img:after {
  content: "";
  left: 0;
  top: 20%;
  position: absolute;
  z-index: 1;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-left: 20px solid #fff;
  border-bottom: 15px solid transparent;
}
.fxt-template-layout13 .fxt-header {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  justify-content: center;
  text-align: center;
}
.fxt-template-layout13 .fxt-header .fxt-logo {
  display: block;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  max-width: 40vw;
}
.fxt-template-layout13 .fxt-header h1 {
  color: #fff;
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 10px;
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout13 .fxt-header h1 {
    font-size: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout13 .fxt-header h1 {
    font-size: 28px;
  }
}
.fxt-template-layout13 .fxt-header p {
  color: #fff;
}
.fxt-template-layout13 .fxt-content {
  padding: 30px;
  max-width: 460px;
  width: 100%;
}
@media only screen and (max-width: 1199px) {
  .fxt-template-layout13 .fxt-content {
    padding: 0;
  }
}
.fxt-template-layout13 .fxt-content h2 {
  font-weight: 700;
  margin-bottom: 50px;
}
.fxt-template-layout13 .fxt-form {
  margin-top: 30px;
}
.fxt-template-layout13 .fxt-form .form-group {
  position: relative;
  z-index: 1;
}
.fxt-template-layout13 .fxt-form .form-group .field-icon {
  position: absolute;
  z-index: 1;
  right: 24px;
  bottom: 18px;
  padding: 0 5px;
  color: #c5c5c5;
  font-size: 14px;
}
.fxt-template-layout13 .fxt-form .form-group .field-icon:before {
  padding: 17px 10px;
}
.fxt-template-layout13 .fxt-form .form-control {
  min-height: 40px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-bottom: 1px solid #e7e7e7;
  padding: 10px 30px 10px 0;
  color: #111;
}
.fxt-template-layout13 .fxt-form input::-webkit-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout13 .fxt-form input::-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout13 .fxt-form input:-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout13 .fxt-form input:-ms-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout13 .fxt-btn-fill {
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 0;
  color: #fff;
  border-radius: 3px;
  background-color: #009BB6;
  padding: 10px 36px;
  margin-bottom: 10px;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout13 .fxt-btn-fill:hover {
  background-color: #02adcb;
  border-color: #02adcb;
}
.fxt-template-layout13 .fxt-btn-fill:focus {
  outline: none;
}
.fxt-template-layout13 ul.fxt-socials {
  text-align: center;
}
.fxt-template-layout13 ul.fxt-socials li {
  display: inline-block;
  margin-right: 4px;
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout13 ul.fxt-socials li {
    margin-right: 2px;
  }
}
.fxt-template-layout13 ul.fxt-socials li:last-child {
  margin-right: 0;
}
.fxt-template-layout13 ul.fxt-socials li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  height: 40px;
  width: 40px;
  color: #ffffff;
  border-radius: 50%;
  border: 1px solid;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout13 ul.fxt-socials li.fxt-facebook a {
  background-color: #3b5998;
  border-color: #3b5998;
}
.fxt-template-layout13 ul.fxt-socials li.fxt-facebook a:hover {
  background-color: transparent;
  color: #3b5998;
}
.fxt-template-layout13 ul.fxt-socials li.fxt-twitter a {
  background-color: #00acee;
  border-color: #00acee;
}
.fxt-template-layout13 ul.fxt-socials li.fxt-twitter a:hover {
  background-color: transparent;
  color: #00acee;
}
.fxt-template-layout13 ul.fxt-socials li.fxt-google a {
  background-color: #CC3333;
  border-color: #CC3333;
}
.fxt-template-layout13 ul.fxt-socials li.fxt-google a:hover {
  background-color: transparent;
  color: #CC3333;
}
.fxt-template-layout13 ul.fxt-socials li.fxt-instagram a {
  background-color: #3f729b;
  border-color: #3f729b;
}
.fxt-template-layout13 ul.fxt-socials li.fxt-instagram a:hover {
  background-color: transparent;
  color: #3f729b;
}
.fxt-template-layout13 ul.fxt-socials li.fxt-linkedin a {
  background-color: #0077B5;
  border-color: #0077B5;
}
.fxt-template-layout13 ul.fxt-socials li.fxt-linkedin a:hover {
  background-color: transparent;
  color: #0077B5;
}
.fxt-template-layout13 ul.fxt-socials li.fxt-youtube a {
  background-color: #c4302b;
  border-color: #c4302b;
}
.fxt-template-layout13 ul.fxt-socials li.fxt-youtube a:hover {
  background-color: transparent;
  color: #c4302b;
}
.fxt-template-layout13 .switcher-text {
  color: #55d1ff;
  font-size: 15px;
  margin-left: 3px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout13 .switcher-text:last-child {
  margin-right: 0;
}
.fxt-template-layout13 .switcher-text:hover {
  color: #666;
}
.fxt-template-layout13 .switcher-text.active {
  color: #666;
}
.fxt-template-layout13 .checkbox {
  padding-left: 5px;
}
.fxt-template-layout13 .checkbox label {
  padding-left: 20px;
  color: #a4a4a4;
  margin-bottom: 0;
  font-size: 15px;
  position: relative;
}
.fxt-template-layout13 .checkbox label:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  top: 4px;
  left: 0;
  margin-left: -5px;
  border: 1px solid;
  border-color: #dcdcdc;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.fxt-template-layout13 .checkbox label:after {
  position: absolute;
  margin-left: -20px;
  padding-left: 3px;
  font-size: 10px;
  color: #555555;
}
.fxt-template-layout13 .checkbox input[type="checkbox"] {
  display: none;
}
.fxt-template-layout13 .checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'Font Awesome 5 Free';
  content: "\f00c";
  font-weight: 900;
  color: #ffffff;
  left: 15px;
  top: 4px;
}
.fxt-template-layout13 .checkbox input[type="checkbox"]:checked + label::before {
  background-color: #009BB6;
  border-color: #009BB6;
}
.fxt-template-layout13 .fxt-footer {
  text-align: center;
}
/*========================================================================
17. Demo Layout Fourteen
=========================================================================*/
.fxt-template-layout14 {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  background-color: #fff;
  padding: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;
}
.fxt-template-layout14:before {
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(4, 4, 14, 0.3);
  left: 0;
  top: 0;
  position: absolute;
  z-index: -1;
}
.fxt-template-layout14 .fxt-checkbox-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.fxt-template-layout14 .fxt-bg-color {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  -webkit-box-shadow: 0px 0px 62px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 62px 0px rgba(0, 0, 0, 0.07);
}
.fxt-template-layout14 .fxt-bg-img {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding: 0;
}
.fxt-template-layout14 .fxt-content {
  padding: 65px 90px 45px;
  width: 100%;
  background-color: #fff;
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout14 .fxt-content {
    padding: 65px 30px 45px;
  }
}
.fxt-template-layout14 .fxt-header {
  text-align: center;
  margin-bottom: 50px;
}
.fxt-template-layout14 .fxt-header .fxt-logo {
  display: block;
  margin-bottom: 7px;
  max-width: 40vw;
  margin-left: auto;
  margin-right: auto;
}
.fxt-template-layout14 .fxt-header h1 {
  color: #fff;
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 10px;
}
.fxt-template-layout14 .fxt-header p {
  color: #999898;
}
.fxt-template-layout14 .fxt-form h2 {
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 5px;
}
.fxt-template-layout14 .fxt-form p {
  font-size: 18px;
  color: #111111;
  text-align: center;
}
.fxt-template-layout14 .fxt-form form .input-label {
  color: #979696;
}
.fxt-template-layout14 .fxt-form .form-group {
  position: relative;
  z-index: 1;
}
.fxt-template-layout14 .fxt-form .form-group .field-icon {
  position: absolute;
  z-index: 1;
  right: 19px;
  bottom: 18px;
  font-size: 14px;
  color: #a1a1a1;
}
.fxt-template-layout14 .fxt-form .form-group .field-icon:before {
  padding: 17px 10px;
}
.fxt-template-layout14 .fxt-form .form-control {
  min-height: 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #e7e7e7;
  padding: 10px 15px;
  color: #111;
}
.fxt-template-layout14 .fxt-form input::-webkit-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout14 .fxt-form input::-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout14 .fxt-form input:-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout14 .fxt-form input:-ms-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout14 .fxt-btn-fill {
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 0;
  color: #fff;
  border-radius: 3px;
  background-color: #e5698e;
  padding: 10px 36px;
  margin-bottom: 10px;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout14 .fxt-btn-fill:hover {
  background-color: #f381a3;
  border-color: #f381a3;
}
.fxt-template-layout14 .fxt-btn-fill:focus {
  outline: none;
}
.fxt-template-layout14 .switcher-text {
  color: #9f9f9f;
  font-size: 15px;
  margin-top: 5px;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout14 .switcher-text:last-child {
  margin-right: 0;
}
.fxt-template-layout14 .switcher-text:hover {
  color: #666;
}
.fxt-template-layout14 .switcher-text.active {
  color: #666;
}
.fxt-template-layout14 .switcher-text2 {
  color: #353535;
  font-size: 15px;
  margin-top: 5px;
  margin-left: 2px;
  display: inline-block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout14 .switcher-text2:last-child {
  margin-right: 0;
}
.fxt-template-layout14 .switcher-text2:hover {
  color: #000;
}
.fxt-template-layout14 .switcher-text2.active {
  color: #000;
}
.fxt-template-layout14 .fxt-style-line {
  overflow: hidden;
  text-align: center;
}
.fxt-template-layout14 .fxt-style-line h3 {
  text-align: center;
  font-weight: 300;
  margin-bottom: 30px;
  font-size: 20px;
  color: #a4a4a4;
  display: inline-block;
  position: relative;
  padding: 0 25px;
  z-index: 1;
}
.fxt-template-layout14 .fxt-style-line h3:before {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #ebebeb;
  left: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.fxt-template-layout14 .fxt-style-line h3:after {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #ebebeb;
  right: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.fxt-template-layout14 ul.fxt-socials {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: 20px;
}
.fxt-template-layout14 ul.fxt-socials li {
  max-width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout14 ul.fxt-socials li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
}
@media only screen and (max-width: 350px) {
  .fxt-template-layout14 ul.fxt-socials li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}
.fxt-template-layout14 ul.fxt-socials li a {
  border-radius: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  height: 45px;
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout14 ul.fxt-socials li a i {
  border-radius: 2px 0 0 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 45px;
  height: 45px;
}
.fxt-template-layout14 ul.fxt-socials li a span {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.fxt-template-layout14 ul.fxt-socials li.fxt-facebook a {
  background-color: #3b5998;
}
.fxt-template-layout14 ul.fxt-socials li.fxt-facebook a i {
  background-color: #4867aa;
}
.fxt-template-layout14 ul.fxt-socials li.fxt-facebook a:hover {
  background-color: #5676bb;
}
.fxt-template-layout14 ul.fxt-socials li.fxt-twitter a {
  background-color: #00acee;
}
.fxt-template-layout14 ul.fxt-socials li.fxt-twitter a i {
  background-color: #33ccff;
}
.fxt-template-layout14 ul.fxt-socials li.fxt-twitter a:hover {
  background-color: #3dc5f3;
}
.fxt-template-layout14 ul.fxt-socials li.fxt-google a {
  background-color: #CC3333;
}
.fxt-template-layout14 ul.fxt-socials li.fxt-google a i {
  background-color: #db4437;
}
.fxt-template-layout14 ul.fxt-socials li.fxt-google a:hover {
  background-color: #e75042;
}
.fxt-template-layout14 .checkbox {
  padding-left: 5px;
}
.fxt-template-layout14 .checkbox label {
  padding-left: 20px;
  color: #a4a4a4;
  margin-bottom: 0;
  font-size: 15px;
  position: relative;
}
.fxt-template-layout14 .checkbox label:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  top: 4px;
  left: 0;
  margin-left: -5px;
  border: 1px solid;
  border-color: #dcdcdc;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.fxt-template-layout14 .checkbox label:after {
  position: absolute;
  margin-left: -20px;
  padding-left: 3px;
  font-size: 10px;
  color: #555555;
}
.fxt-template-layout14 .checkbox input[type="checkbox"] {
  display: none;
}
.fxt-template-layout14 .checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'Font Awesome 5 Free';
  content: "\f00c";
  font-weight: 900;
  color: #ffffff;
  left: 15px;
  top: 4px;
}
.fxt-template-layout14 .checkbox input[type="checkbox"]:checked + label::before {
  background-color: #e5698e;
  border-color: #e5698e;
}
.fxt-template-layout14 .fxt-footer {
  text-align: center;
}
.fxt-template-layout14 .fxt-footer p {
  color: #999898;
}
/*========================================================================
18. Demo Layout Fifteen
=========================================================================*/
.fxt-template-layout15 .fxt-checkbox-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.fxt-template-layout15 .fxt-bg-color {
  background-color: #ffffff;
  min-height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 60px 15px 52px;
}
.fxt-template-layout15 .fxt-bg-img {
  min-height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 50px 15px 30px;
  position: relative;
  z-index: 1;
}
.fxt-template-layout15 .fxt-bg-img:before {
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(4, 4, 14, 0.6);
  left: 0;
  top: 0;
  position: absolute;
  z-index: -1;
}
.fxt-template-layout15 .fxt-intro {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 480px;
  width: 100%;
}
.fxt-template-layout15 .fxt-intro h1 {
  color: #fff;
  font-weight: 700;
}
.fxt-template-layout15 .fxt-intro p {
  color: #fff;
  margin-bottom: 50px;
}
.fxt-template-layout15 .fxt-intro .fxt-page-switcher {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 25px;
}
.fxt-template-layout15 .fxt-intro .fxt-page-switcher .switcher-text1 {
  color: #fff;
  font-size: 18px;
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid #f1f1f1;
  line-height: 1;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout15 .fxt-intro .fxt-page-switcher .switcher-text1 {
    margin-right: 30px;
  }
}
@media only screen and (max-width: 479px) {
  .fxt-template-layout15 .fxt-intro .fxt-page-switcher .switcher-text1 {
    margin-right: 15px;
    padding-right: 10px;
    font-size: 16px;
  }
}
@media only screen and (max-width: 320px) {
  .fxt-template-layout15 .fxt-intro .fxt-page-switcher .switcher-text1 {
    margin-right: 10px;
    padding-right: 5px;
  }
}
.fxt-template-layout15 .fxt-intro .fxt-page-switcher .switcher-text1:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: 0;
}
.fxt-template-layout15 .fxt-intro .fxt-page-switcher .switcher-text1:hover {
  color: #dddddd;
}
.fxt-template-layout15 .fxt-intro .fxt-page-switcher .switcher-text1.active {
  color: #e9b102;
}
.fxt-template-layout15 .fxt-content {
  max-width: 450px;
  width: 100%;
}
.fxt-template-layout15 .fxt-header .fxt-logo {
  display: block;
  margin-bottom: 100px;
  max-width: 40vw;
}
.fxt-template-layout15 .fxt-form {
  margin-bottom: 40px;
}
.fxt-template-layout15 .fxt-form h2 {
  font-weight: 700;
  margin-bottom: 5px;
}
.fxt-template-layout15 .fxt-form p {
  margin-bottom: 30px;
  font-size: 17px;
}
.fxt-template-layout15 .fxt-form .form-group {
  position: relative;
  z-index: 1;
}
.fxt-template-layout15 .fxt-form .form-group i {
  position: absolute;
  z-index: 1;
  right: 5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.fxt-template-layout15 .fxt-form .form-group [class^="flaticon-"]:before,
.fxt-template-layout15 .fxt-form .form-group [class*=" flaticon-"]:before,
.fxt-template-layout15 .fxt-form .form-group [class^="flaticon-"]:after,
.fxt-template-layout15 .fxt-form .form-group [class*=" flaticon-"]:after {
  margin-left: 0;
  font-size: 17px;
  color: #a1a1a1;
}
.fxt-template-layout15 .fxt-form .form-control {
  min-height: 40px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-bottom: 1px solid #e7e7e7;
  padding: 10px 30px 10px 0;
  color: #111111;
}
.fxt-template-layout15 .fxt-form input::-webkit-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout15 .fxt-form input::-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout15 .fxt-form input:-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout15 .fxt-form input:-ms-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout15 .fxt-btn-fill {
  margin-top: 15px;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 0;
  color: #fff;
  border-radius: 3px;
  background-color: #e9b102;
  padding: 10px 36px;
  margin-bottom: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout15 .fxt-btn-fill:hover {
  background-color: #ecb719;
  border-color: #ecb719;
}
.fxt-template-layout15 .fxt-btn-fill:focus {
  outline: none;
}
.fxt-template-layout15 .switcher-text2 {
  color: #9f9f9f;
  font-size: 15px;
  margin-top: 5px;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout15 .switcher-text2:last-child {
  margin-right: 0;
}
.fxt-template-layout15 .switcher-text2:hover {
  color: #666;
}
.fxt-template-layout15 .switcher-text2.active {
  color: #666;
}
.fxt-template-layout15 ul.fxt-socials li {
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 8px;
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout15 ul.fxt-socials li {
    margin-right: 2px;
  }
}
.fxt-template-layout15 ul.fxt-socials li:last-child {
  margin-right: 0;
}
.fxt-template-layout15 ul.fxt-socials li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  height: 40px;
  width: 40px;
  color: #ffffff;
  border-radius: 50%;
  border: 1px solid;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout15 ul.fxt-socials li.fxt-facebook a {
  background-color: #3b5998;
  border-color: #3b5998;
}
.fxt-template-layout15 ul.fxt-socials li.fxt-facebook a:hover {
  background-color: transparent;
  color: #3b5998;
}
.fxt-template-layout15 ul.fxt-socials li.fxt-twitter a {
  background-color: #00acee;
  border-color: #00acee;
}
.fxt-template-layout15 ul.fxt-socials li.fxt-twitter a:hover {
  background-color: transparent;
  color: #00acee;
}
.fxt-template-layout15 ul.fxt-socials li.fxt-google a {
  background-color: #CC3333;
  border-color: #CC3333;
}
.fxt-template-layout15 ul.fxt-socials li.fxt-google a:hover {
  background-color: transparent;
  color: #CC3333;
}
.fxt-template-layout15 ul.fxt-socials li.fxt-instagram a {
  background-color: #3f729b;
  border-color: #3f729b;
}
.fxt-template-layout15 ul.fxt-socials li.fxt-instagram a:hover {
  background-color: transparent;
  color: #3f729b;
}
.fxt-template-layout15 ul.fxt-socials li.fxt-linkedin a {
  background-color: #0077B5;
  border-color: #0077B5;
}
.fxt-template-layout15 ul.fxt-socials li.fxt-linkedin a:hover {
  background-color: transparent;
  color: #0077B5;
}
.fxt-template-layout15 ul.fxt-socials li.fxt-youtube a {
  background-color: #c4302b;
  border-color: #c4302b;
}
.fxt-template-layout15 ul.fxt-socials li.fxt-youtube a:hover {
  background-color: transparent;
  color: #c4302b;
}
.fxt-template-layout15 ul.fxt-socials li.fxt-pinterest a {
  background-color: #bd081c;
  border-color: #bd081c;
}
.fxt-template-layout15 ul.fxt-socials li.fxt-pinterest a:hover {
  background-color: transparent;
  color: #bd081c;
}
.fxt-template-layout15 .fxt-footer {
  text-align: center;
}
/*========================================================================
19. Demo Layout Sixteen
=========================================================================*/
.fxt-template-layout16 {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  background-color: #111;
  padding: 15px;
}
.fxt-template-layout16 .fxt-checkbox-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.fxt-template-layout16 .fxt-bg-color {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
}
.fxt-template-layout16 .fxt-bg-img {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding: 0;
}
.fxt-template-layout16 .fxt-content {
  padding: 65px 90px 45px;
  width: 100%;
  background-color: #fff;
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout16 .fxt-content {
    padding: 65px 30px 45px;
  }
}
.fxt-template-layout16 .fxt-header {
  text-align: center;
  margin-bottom: 50px;
}
.fxt-template-layout16 .fxt-header .fxt-logo {
  display: block;
  margin-bottom: 7px;
  max-width: 40vw;
  margin-left: auto;
  margin-right: auto;
}
.fxt-template-layout16 .fxt-header h1 {
  color: #fff;
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 10px;
}
.fxt-template-layout16 .fxt-header p {
  color: #999898;
}
.fxt-template-layout16 .fxt-form h2 {
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 5px;
}
.fxt-template-layout16 .fxt-form p {
  font-size: 18px;
  color: #111111;
  text-align: center;
}
.fxt-template-layout16 .fxt-form form .input-label {
  color: #979696;
}
.fxt-template-layout16 .fxt-form .form-group {
  position: relative;
  z-index: 1;
}
.fxt-template-layout16 .fxt-form .form-group .field-icon {
  position: absolute;
  z-index: 1;
  right: 19px;
  bottom: 18px;
  font-size: 14px;
  color: #a1a1a1;
}
.fxt-template-layout16 .fxt-form .form-group .field-icon:before {
  padding: 17px 10px;
}
.fxt-template-layout16 .fxt-form .form-control {
  min-height: 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #e7e7e7;
  padding: 10px 15px;
  color: #111;
}
.fxt-template-layout16 .fxt-form input::-webkit-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout16 .fxt-form input::-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout16 .fxt-form input:-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout16 .fxt-form input:-ms-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout16 .fxt-btn-fill {
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 0;
  color: #fff;
  border-radius: 3px;
  background-color: #111;
  padding: 10px 36px;
  margin-bottom: 10px;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout16 .fxt-btn-fill:hover {
  background-color: #333;
  border-color: #333;
}
.fxt-template-layout16 .fxt-btn-fill:focus {
  outline: none;
}
.fxt-template-layout16 .switcher-text {
  color: #9f9f9f;
  font-size: 15px;
  margin-top: 5px;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout16 .switcher-text:last-child {
  margin-right: 0;
}
.fxt-template-layout16 .switcher-text:hover {
  color: #666;
}
.fxt-template-layout16 .switcher-text.active {
  color: #666;
}
.fxt-template-layout16 .switcher-text2 {
  color: #353535;
  font-size: 15px;
  margin-top: 5px;
  margin-left: 2px;
  display: inline-block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout16 .switcher-text2:last-child {
  margin-right: 0;
}
.fxt-template-layout16 .switcher-text2:hover {
  color: #000;
}
.fxt-template-layout16 .switcher-text2.active {
  color: #000;
}
.fxt-template-layout16 .fxt-style-line {
  overflow: hidden;
  text-align: center;
}
.fxt-template-layout16 .fxt-style-line h3 {
  text-align: center;
  font-weight: 300;
  margin-bottom: 30px;
  font-size: 20px;
  color: #a4a4a4;
  display: inline-block;
  position: relative;
  padding: 0 25px;
  z-index: 1;
}
.fxt-template-layout16 .fxt-style-line h3:before {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #ebebeb;
  left: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.fxt-template-layout16 .fxt-style-line h3:after {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #ebebeb;
  right: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.fxt-template-layout16 ul.fxt-socials {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: 20px;
}
.fxt-template-layout16 ul.fxt-socials li {
  max-width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout16 ul.fxt-socials li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
}
@media only screen and (max-width: 350px) {
  .fxt-template-layout16 ul.fxt-socials li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}
.fxt-template-layout16 ul.fxt-socials li a {
  border-radius: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  height: 45px;
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout16 ul.fxt-socials li a i {
  border-radius: 2px 0 0 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 45px;
  height: 45px;
}
.fxt-template-layout16 ul.fxt-socials li a span {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.fxt-template-layout16 ul.fxt-socials li.fxt-facebook a {
  background-color: #3b5998;
}
.fxt-template-layout16 ul.fxt-socials li.fxt-facebook a i {
  background-color: #4867aa;
}
.fxt-template-layout16 ul.fxt-socials li.fxt-facebook a:hover {
  background-color: #5676bb;
}
.fxt-template-layout16 ul.fxt-socials li.fxt-twitter a {
  background-color: #00acee;
}
.fxt-template-layout16 ul.fxt-socials li.fxt-twitter a i {
  background-color: #33ccff;
}
.fxt-template-layout16 ul.fxt-socials li.fxt-twitter a:hover {
  background-color: #3dc5f3;
}
.fxt-template-layout16 ul.fxt-socials li.fxt-google a {
  background-color: #CC3333;
}
.fxt-template-layout16 ul.fxt-socials li.fxt-google a i {
  background-color: #db4437;
}
.fxt-template-layout16 ul.fxt-socials li.fxt-google a:hover {
  background-color: #e75042;
}
.fxt-template-layout16 .checkbox {
  padding-left: 5px;
}
.fxt-template-layout16 .checkbox label {
  padding-left: 20px;
  color: #a4a4a4;
  margin-bottom: 0;
  font-size: 15px;
  position: relative;
}
.fxt-template-layout16 .checkbox label:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  top: 4px;
  left: 0;
  margin-left: -5px;
  border: 1px solid;
  border-color: #dcdcdc;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.fxt-template-layout16 .checkbox label:after {
  position: absolute;
  margin-left: -20px;
  padding-left: 3px;
  font-size: 10px;
  color: #555555;
}
.fxt-template-layout16 .checkbox input[type="checkbox"] {
  display: none;
}
.fxt-template-layout16 .checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'Font Awesome 5 Free';
  content: "\f00c";
  font-weight: 900;
  color: #ffffff;
  left: 15px;
  top: 4px;
}
.fxt-template-layout16 .checkbox input[type="checkbox"]:checked + label::before {
  background-color: #111;
  border-color: #111;
}
.fxt-template-layout16 .fxt-footer {
  text-align: center;
}
.fxt-template-layout16 .fxt-footer p {
  color: #999898;
}
/*========================================================================
20. Demo Layout Seventeen
=========================================================================*/
.fxt-template-layout17 {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  background-color: #fff;
  padding: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.fxt-template-layout17 .fxt-checkbox-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.fxt-template-layout17 .fxt-bg-color {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-box-shadow: 3px 0 79px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 3px 0 79px 0 rgba(0, 0, 0, 0.1);
}
.fxt-template-layout17 .fxt-content {
  padding: 100px 65px 70px 80px;
  width: 100%;
}
@media only screen and (max-width: 1199px) {
  .fxt-template-layout17 .fxt-content {
    padding: 100px 35px 70px 50px;
  }
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout17 .fxt-content {
    padding: 100px 70px 70px 70px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout17 .fxt-content {
    padding: 80px 50px 50px 50px;
  }
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout17 .fxt-content {
    padding: 60px 30px 30px 30px;
  }
}
@media only screen and (max-width: 479px) {
  .fxt-template-layout17 .fxt-content {
    padding: 50px 20px 20px 20px;
  }
}
.fxt-template-layout17 .fxt-header {
  text-align: center;
}
.fxt-template-layout17 .fxt-logo {
  display: block;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  max-width: 40vw;
}
.fxt-template-layout17 .fxt-form .form-group {
  position: relative;
  z-index: 1;
}
.fxt-template-layout17 .fxt-form .form-control {
  min-height: 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #e7e7e7;
  padding: 10px 15px;
  color: #111111;
}
.fxt-template-layout17 .fxt-form input::-webkit-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout17 .fxt-form input::-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout17 .fxt-form input:-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout17 .fxt-form input:-ms-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout17 .fxt-btn-fill {
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 0;
  color: #fff;
  border-radius: 3px;
  background-color: #46a203;
  padding: 10px 36px;
  margin-bottom: 10px;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout17 .fxt-btn-fill:hover {
  background-color: #52af10;
  border-color: #52af10;
}
.fxt-template-layout17 .fxt-btn-fill:focus {
  outline: none;
}
.fxt-template-layout17 .switcher-text {
  color: #63bbff;
  font-size: 15px;
  margin-left: 3px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout17 .switcher-text:last-child {
  margin-right: 0;
}
.fxt-template-layout17 .switcher-text:hover {
  color: #666;
}
.fxt-template-layout17 .switcher-text.active {
  color: #666;
}
.fxt-template-layout17 .fxt-style-line {
  overflow: hidden;
  text-align: center;
}
.fxt-template-layout17 .fxt-style-line h2 {
  text-align: center;
  font-weight: 300;
  margin-bottom: 30px;
  font-size: 20px;
  color: #a4a4a4;
  display: inline-block;
  position: relative;
  padding: 0 25px;
  z-index: 1;
}
.fxt-template-layout17 .fxt-style-line h2:before {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #ebebeb;
  left: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.fxt-template-layout17 .fxt-style-line h2:after {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #ebebeb;
  right: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.fxt-template-layout17 ul.fxt-socials {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: 20px;
}
.fxt-template-layout17 ul.fxt-socials li {
  max-width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout17 ul.fxt-socials li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
}
@media only screen and (max-width: 350px) {
  .fxt-template-layout17 ul.fxt-socials li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}
.fxt-template-layout17 ul.fxt-socials li a {
  border-radius: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  height: 45px;
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout17 ul.fxt-socials li a i {
  border-radius: 2px 0 0 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 45px;
  height: 45px;
}
.fxt-template-layout17 ul.fxt-socials li a span {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.fxt-template-layout17 ul.fxt-socials li.fxt-facebook a {
  background-color: #3b5998;
}
.fxt-template-layout17 ul.fxt-socials li.fxt-facebook a i {
  background-color: #4867aa;
}
.fxt-template-layout17 ul.fxt-socials li.fxt-facebook a:hover {
  background-color: #5676bb;
}
.fxt-template-layout17 ul.fxt-socials li.fxt-twitter a {
  background-color: #00acee;
}
.fxt-template-layout17 ul.fxt-socials li.fxt-twitter a i {
  background-color: #33ccff;
}
.fxt-template-layout17 ul.fxt-socials li.fxt-twitter a:hover {
  background-color: #3dc5f3;
}
.fxt-template-layout17 ul.fxt-socials li.fxt-google a {
  background-color: #CC3333;
}
.fxt-template-layout17 ul.fxt-socials li.fxt-google a i {
  background-color: #db4437;
}
.fxt-template-layout17 ul.fxt-socials li.fxt-google a:hover {
  background-color: #e75042;
}
.fxt-template-layout17 .checkbox {
  padding-left: 5px;
}
.fxt-template-layout17 .checkbox label {
  padding-left: 20px;
  color: #a4a4a4;
  margin-bottom: 0;
  font-size: 15px;
  position: relative;
}
.fxt-template-layout17 .checkbox label:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  top: 4px;
  left: 0;
  margin-left: -5px;
  border: 1px solid;
  border-color: #dcdcdc;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.fxt-template-layout17 .checkbox label:after {
  position: absolute;
  margin-left: -20px;
  padding-left: 3px;
  font-size: 10px;
  color: #555555;
}
.fxt-template-layout17 .checkbox input[type="checkbox"] {
  display: none;
}
.fxt-template-layout17 .checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'Font Awesome 5 Free';
  content: "\f00c";
  font-weight: 900;
  color: #ffffff;
  left: 15px;
  top: 4px;
}
.fxt-template-layout17 .checkbox input[type="checkbox"]:checked + label::before {
  background-color: #46a203;
  border-color: #46a203;
}
.fxt-template-layout17 .fxt-footer {
  text-align: center;
}
/*========================================================================
21. Demo Layout Eighteen
=========================================================================*/
.fxt-template-layout18 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  min-height: 100vh;
  z-index: 1;
  padding: 15px;
}
.fxt-template-layout18:before {
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  left: 0;
  top: 0;
  position: absolute;
  z-index: -1;
}
.fxt-template-layout18 .fxt-checkbox-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.fxt-template-layout18 .fxt-content {
  max-width: 600px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 80px 80px 60px;
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout18 .fxt-content {
    padding: 70px 60px 50px;
  }
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout18 .fxt-content {
    padding: 60px 40px 40px;
  }
}
@media only screen and (max-width: 479px) {
  .fxt-template-layout18 .fxt-content {
    padding: 50px 20px 30px;
  }
}
.fxt-template-layout18 .fxt-header {
  text-align: center;
  margin-bottom: 50px;
}
.fxt-template-layout18 .fxt-logo {
  display: block;
  margin-bottom: 100px;
  margin-left: auto;
  margin-right: auto;
  max-width: 40vw;
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout18 .fxt-logo {
    margin-bottom: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout18 .fxt-logo {
    margin-bottom: 60px;
  }
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout18 .fxt-logo {
    margin-bottom: 40px;
  }
}
.fxt-template-layout18 .fxt-form p {
  font-size: 20px;
  color: #fff;
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout18 .fxt-form p {
    text-align: center;
  }
}
.fxt-template-layout18 .fxt-form .form-group {
  position: relative;
  z-index: 1;
}
.fxt-template-layout18 .fxt-form .form-group .field-icon {
  position: absolute;
  z-index: 1;
  right: 19px;
  bottom: 18px;
  font-size: 14px;
  color: #bebebe;
}
.fxt-template-layout18 .fxt-form .form-group .field-icon:before {
  padding: 17px 10px;
}
.fxt-template-layout18 .fxt-form .form-control {
  min-height: 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 10px 15px;
  background-color: transparent;
  color: #fff;
}
.fxt-template-layout18 .fxt-form input::-webkit-input-placeholder {
  color: #bebebe;
  font-size: 18px;
  font-weight: 300;
}
.fxt-template-layout18 .fxt-form input::-moz-placeholder {
  color: #bebebe;
  font-size: 18px;
  font-weight: 300;
}
.fxt-template-layout18 .fxt-form input:-moz-placeholder {
  color: #bebebe;
  font-size: 18px;
  font-weight: 300;
}
.fxt-template-layout18 .fxt-form input:-ms-input-placeholder {
  color: #bebebe;
  font-size: 18px;
  font-weight: 300;
}
.fxt-template-layout18 .fxt-btn-fill {
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 0;
  color: #fff;
  border-radius: 3px;
  background-color: #1fbe66;
  padding: 10px 36px;
  margin-bottom: 10px;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout18 .fxt-btn-fill:hover {
  background-color: #17a156;
  border-color: #17a156;
}
.fxt-template-layout18 .fxt-btn-fill:focus {
  outline: none;
}
.fxt-template-layout18 .switcher-text {
  color: #b6b6b6;
  font-size: 15px;
  margin-top: 5px;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout18 .switcher-text:last-child {
  margin-right: 0;
}
.fxt-template-layout18 .switcher-text:hover {
  color: #e6e6e6;
}
.fxt-template-layout18 .switcher-text2 {
  color: #d4d4d4;
  font-size: 15px;
  margin-top: 5px;
  margin-left: 2px;
  display: inline-block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout18 .switcher-text2:last-child {
  margin-right: 0;
}
.fxt-template-layout18 .switcher-text2:hover {
  color: #e6e6e6;
}
.fxt-template-layout18 .fxt-style-line {
  overflow: hidden;
  text-align: center;
}
.fxt-template-layout18 .fxt-style-line h3 {
  text-align: center;
  font-weight: 300;
  margin-bottom: 30px;
  font-size: 20px;
  color: #a4a4a4;
  display: inline-block;
  position: relative;
  padding: 0 25px;
  z-index: 1;
}
.fxt-template-layout18 .fxt-style-line h3:before {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #a4a4a4;
  left: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.fxt-template-layout18 .fxt-style-line h3:after {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #a4a4a4;
  right: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.fxt-template-layout18 ul.fxt-socials {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: 20px;
}
.fxt-template-layout18 ul.fxt-socials li {
  max-width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout18 ul.fxt-socials li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
}
@media only screen and (max-width: 350px) {
  .fxt-template-layout18 ul.fxt-socials li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}
.fxt-template-layout18 ul.fxt-socials li a {
  border-radius: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  height: 45px;
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout18 ul.fxt-socials li a i {
  border-radius: 2px 0 0 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 45px;
  height: 45px;
}
.fxt-template-layout18 ul.fxt-socials li a span {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.fxt-template-layout18 ul.fxt-socials li.fxt-facebook a {
  background-color: #3b5998;
}
.fxt-template-layout18 ul.fxt-socials li.fxt-facebook a i {
  background-color: #4867aa;
}
.fxt-template-layout18 ul.fxt-socials li.fxt-facebook a:hover {
  background-color: #5676bb;
}
.fxt-template-layout18 ul.fxt-socials li.fxt-twitter a {
  background-color: #00acee;
}
.fxt-template-layout18 ul.fxt-socials li.fxt-twitter a i {
  background-color: #33ccff;
}
.fxt-template-layout18 ul.fxt-socials li.fxt-twitter a:hover {
  background-color: #3dc5f3;
}
.fxt-template-layout18 ul.fxt-socials li.fxt-google a {
  background-color: #CC3333;
}
.fxt-template-layout18 ul.fxt-socials li.fxt-google a i {
  background-color: #db4437;
}
.fxt-template-layout18 ul.fxt-socials li.fxt-google a:hover {
  background-color: #e75042;
}
.fxt-template-layout18 .checkbox {
  padding-left: 5px;
  margin-right: 30px;
}
.fxt-template-layout18 .checkbox label {
  padding-left: 20px;
  color: #b9b9b9;
  margin-bottom: 0;
  font-size: 15px;
  position: relative;
}
.fxt-template-layout18 .checkbox label:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  top: 4px;
  left: 0;
  margin-left: -5px;
  border: 1px solid;
  border-color: #dcdcdc;
  border-radius: 2px;
  background-color: transparent;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.fxt-template-layout18 .checkbox label:after {
  position: absolute;
  margin-left: -20px;
  padding-left: 3px;
  font-size: 10px;
  color: #555555;
}
.fxt-template-layout18 .checkbox input[type="checkbox"] {
  display: none;
}
.fxt-template-layout18 .checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'Font Awesome 5 Free';
  content: "\f00c";
  font-weight: 900;
  color: #ffffff;
  left: 15px;
  top: 4px;
}
.fxt-template-layout18 .checkbox input[type="checkbox"]:checked + label::before {
  background-color: #1fbe66;
  border-color: #1fbe66;
}
.fxt-template-layout18 .fxt-footer {
  text-align: center;
}
.fxt-template-layout18 .fxt-footer p {
  color: #b6b6b6;
}
/*========================================================================
22. Demo Layout Nineteen
=========================================================================*/
.fxt-template-layout19 .fxt-checkbox-area {
  text-align: right;
}
.fxt-template-layout19 .fxt-bg-color {
  background-color: #F7F7F7;
  min-height: 100vh;
  /* width: 100%; */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 60px 30px;
}
.fxt-template-layout19 .fxt-bg-img {
  min-height: 100vh;
  /* width: 100%; */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 30px 15px;
  position: relative;
  z-index: 1;
    
}
.fxt-template-layout19 .fxt-bg-img:before {
  content: "";
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.fxt-template-layout19 .fxt-content {
  max-width: 450px;
    margin-top: 60px;
  width: 100%;
}
.fxt-template-layout19 .fxt-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
    text-align: right;
  width: 100%;
    position: absolute;
    top: 0;
    left: -12px;
  
     
}
.fxt-header-esquerda {
    position: absolute;
    top: 21px;
    left: 21px;
}
.fxt-template-layout19 .fxt-header .fxt-logo {
  display: block;
  margin-bottom: 179px;
  max-width: 40vw;
}
.fxt-template-layout19 .fxt-header h1 {
  color: #fff;
    
}
.fxt-template-layout19 .fxt-header p {
  color: #404040;
    font-size: 20px;
}
.fxt-template-layout19 .fxt-form {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-bottom: 40px;
}
.fxt-template-layout19 .fxt-form h2 {
  font-weight: 500;
  font-size: 30px;
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout19 .fxt-form h2 {
    font-size: 22px;
    margin-bottom: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout19 .fxt-form h2 {
    font-size: 20px;
    margin-bottom: 40px;
  }
}
.fxt-template-layout19 .fxt-form .form-group {
  position: relative;
  z-index: 1;
}
.fxt-template-layout19 .fxt-form .form-group .field-icon {
  position: absolute;
  z-index: 1;
  right: 8px;
  top: 50%;
  color: #9f9f9f;
  font-size: 14px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.fxt-template-layout19 .fxt-form .form-group .field-icon:before {
  padding: 12px 0 12px 10px;
}
.chat-esquerda {
    position: absolute;
    left: 30px;
    bottom: 30px;
}
.fxt-template-layout19 .fxt-form .form-control {
  min-height: 40px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-bottom: 1px solid #e7e7e7;
  padding: 10px 30px 10px 10;
  color: #111;
    border-radius: 170px;
}
.fxt-template-layout19 .fxt-form input::-webkit-input-placeholder {
  color: #999999;
  font-size: 18px;
  font-weight: 300;
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout19 .fxt-form input::-webkit-input-placeholder {
    font-size: 17px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout19 .fxt-form input::-webkit-input-placeholder {
    font-size: 16px;
  }
}
.fxt-template-layout19 .fxt-form input::-moz-placeholder {
  color: #999999;
  font-size: 18px;
  font-weight: 300;
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout19 .fxt-form input::-moz-placeholder {
    font-size: 17px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout19 .fxt-form input::-moz-placeholder {
    font-size: 16px;
  }
}
.fxt-template-layout19 .fxt-form input:-moz-placeholder {
  color: #999999;
  font-size: 18px;
  font-weight: 300;
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout19 .fxt-form input:-moz-placeholder {
    font-size: 17px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout19 .fxt-form input:-moz-placeholder {
    font-size: 16px;
  }
}
.fxt-template-layout19 .fxt-form input:-ms-input-placeholder {
  color: #999999;
  font-size: 18px;
  font-weight: 300;
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout19 .fxt-form input:-ms-input-placeholder {
    font-size: 17px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout19 .fxt-form input:-ms-input-placeholder {
    font-size: 16px;
  }
}
.fxt-template-layout19 .fxt-btn-fill {
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 0;
  color: #fff;
  border-radius: 170px;
  background-color: #6b24d6;
  padding: 6px 61px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout19 .fxt-btn-fill:hover {
  background-color: #ac01bd;
}
.fxt-template-layout19 .fxt-btn-fill:focus {
  outline: none;
}
.fxt-template-layout19 .fxt-btn-ghost {
  margin-top: 15px;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 2px solid #688EFF;
  color: #000;
  border-radius: 170px;
  background-color: #fff;
  padding: 6px 41px;
  margin-left: 16px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-header-rodape {
    position: absolute;
    bottom: 0;
    text-align: center;
}
.fxt-template-layout19 .fxt-btn-ghost:hover {
  background-color: #fff;
  border-color: #1763C7;
  color: #000;
}
.fxt-template-layout19 .fxt-btn-ghost:focus {
  outline: none;
}
.fxt-template-layout19 .switcher-text2 {
  color: #688EFF;
  font-size: 13px;
  margin-top: 5px;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout19 .switcher-text2:last-child {
  margin-right: 0;
}
.fxt-template-layout19 .switcher-text2:hover {
  color: #666;
}
.fxt-template-layout19 .switcher-text2.active {
  color: #666;
}
.fxt-template-layout19 .checkbox {
  padding-left: 5px;
  margin-right: 10px;
}
.fxt-template-layout19 .checkbox label {
  padding-left: 20px;
  color: #9f9f9f;
  margin-bottom: 0;
  font-size: 15px;
  position: relative;
}
.fxt-template-layout19 .checkbox label:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  top: 4px;
  left: 0;
  margin-left: -5px;
  border: 1px solid;
  border-color: #dcdcdc;
  border-radius: 2px;
  background-color: transparent;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.fxt-template-layout19 .checkbox label:after {
  position: absolute;
  margin-left: -20px;
  padding-left: 3px;
  font-size: 10px;
  color: #555555;
}
.fxt-template-layout19 .checkbox input[type="checkbox"] {
  display: none;
}
.fxt-template-layout19 .checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'Font Awesome 5 Free';
  content: "\f00c";
  font-weight: 900;
  color: #ffffff;
  left: 15px;
  top: 4px;
}
.fxt-template-layout19 .checkbox input[type="checkbox"]:checked + label::before {
  background-color: #2670d4;
  border-color: #2670d4;
}
.fxt-template-layout19 ul.fxt-socials li {
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 5px;
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout19 ul.fxt-socials li {
    margin-right: 2px;
  }
}
.fxt-template-layout19 ul.fxt-socials li:last-child {
  margin-right: 0;
}
.fxt-template-layout19 ul.fxt-socials li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid;
  border-color: #dadada;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout19 ul.fxt-socials li.fxt-facebook a {
  color: #3b5998;
}
.fxt-template-layout19 ul.fxt-socials li.fxt-facebook a:hover {
  border-color: #3b5998;
  background-color: #3b5998;
  color: #fff;
}
.fxt-template-layout19 ul.fxt-socials li.fxt-twitter a {
  color: #00acee;
}
.fxt-template-layout19 ul.fxt-socials li.fxt-twitter a:hover {
  border-color: #00acee;
  background-color: #00acee;
  color: #fff;
}
.fxt-template-layout19 ul.fxt-socials li.fxt-google a {
  color: #CC3333;
}
.fxt-template-layout19 ul.fxt-socials li.fxt-google a:hover {
  border-color: #CC3333;
  background-color: #CC3333;
  color: #fff;
}
.fxt-template-layout19 ul.fxt-socials li.fxt-instagram a {
  color: #3f729b;
}
.fxt-template-layout19 ul.fxt-socials li.fxt-instagram a:hover {
  border-color: #3f729b;
  background-color: #3f729b;
  color: #fff;
}
.fxt-template-layout19 ul.fxt-socials li.fxt-linkedin a {
  color: #0077B5;
}
.fxt-template-layout19 ul.fxt-socials li.fxt-linkedin a:hover {
  border-color: #0077B5;
  background-color: #0077B5;
  color: #fff;
}
.fxt-template-layout19 ul.fxt-socials li.fxt-youtube a {
  color: #c4302b;
}
.fxt-template-layout19 ul.fxt-socials li.fxt-youtube a:hover {
  border-color: #c4302b;
  background-color: #c4302b;
  color: #fff;
}
.fxt-template-layout19 ul.fxt-socials li.fxt-pinterest a {
  color: #bd081c;
}
.fxt-template-layout19 ul.fxt-socials li.fxt-pinterest a:hover {
  border-color: #bd081c;
  background-color: #bd081c;
  color: #fff;
}
.fxt-template-layout19 .fxt-footer {
  margin-top: 50px;
  text-align: center;
}
/*========================================================================
23. Demo Layout Twenty
=========================================================================*/
.fxt-template-layout20 {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout20 {
    padding: 15px;
  }
}
@media only screen and (min-width: 1380px) {
  .fxt-template-layout20 .container {
    max-width: 1350px;
  }
}
.fxt-template-layout20 .fxt-checkbox-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 40px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.fxt-template-layout20 .fxt-bg-color {
  background-color: #ffffff;
  width: 100%;
  padding: 0;
}
.fxt-template-layout20 .fxt-bg-img {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding: 0;
  border-radius: 15px 0 0 15px;
}
.fxt-template-layout20 .fxt-content {
  border-radius: 0 15px 15px 0;
  padding: 100px 50px 70px 50px;
  width: 100%;
  -webkit-box-shadow: 3px 0 79px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 3px 0 79px 0 rgba(0, 0, 0, 0.08);
}
@media only screen and (max-width: 1199px) {
  .fxt-template-layout20 .fxt-content {
    padding: 100px 35px 70px 50px;
  }
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout20 .fxt-content {
    padding: 100px 70px 70px 70px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout20 .fxt-content {
    padding: 80px 50px 50px 50px;
  }
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout20 .fxt-content {
    padding: 60px 30px 30px 30px;
  }
}
@media only screen and (max-width: 479px) {
  .fxt-template-layout20 .fxt-content {
    padding: 50px 20px 20px 20px;
  }
}
.fxt-template-layout20 .fxt-header {
  text-align: center;
}
.fxt-template-layout20 .fxt-logo {
  display: block;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  max-width: 40vw;
}
.fxt-template-layout20 .fxt-form .form-group {
  position: relative;
  z-index: 1;
}
.fxt-template-layout20 .fxt-form .form-control {
  min-height: 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #e7e7e7;
  padding: 10px 15px;
  color: #111111;
}
.fxt-template-layout20 .fxt-form input::-webkit-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout20 .fxt-form input::-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout20 .fxt-form input:-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout20 .fxt-form input:-ms-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout20 .fxt-btn-fill {
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 0;
  color: #fff;
  border-radius: 3px;
  background-color: #6b79fd;
  padding: 10px 36px;
  margin-bottom: 10px;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout20 .fxt-btn-fill:hover {
  background-color: #4f5fec;
  border-color: #4f5fec;
}
.fxt-template-layout20 .fxt-btn-fill:focus {
  outline: none;
}
.fxt-template-layout20 .switcher-text {
  color: #63bbff;
  font-size: 15px;
  margin-left: 3px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout20 .switcher-text:last-child {
  margin-right: 0;
}
.fxt-template-layout20 .switcher-text:hover {
  color: #666;
}
.fxt-template-layout20 .switcher-text.active {
  color: #666;
}
.fxt-template-layout20 .fxt-style-line {
  overflow: hidden;
  text-align: center;
}
.fxt-template-layout20 .fxt-style-line h2 {
  text-align: center;
  font-weight: 300;
  margin-bottom: 30px;
  font-size: 20px;
  color: #a4a4a4;
  display: inline-block;
  position: relative;
  padding: 0 25px;
  z-index: 1;
}
.fxt-template-layout20 .fxt-style-line h2:before {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #ebebeb;
  left: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.fxt-template-layout20 .fxt-style-line h2:after {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #ebebeb;
  right: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.fxt-template-layout20 ul.fxt-socials {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: 20px;
}
.fxt-template-layout20 ul.fxt-socials li {
  max-width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout20 ul.fxt-socials li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
}
@media only screen and (max-width: 350px) {
  .fxt-template-layout20 ul.fxt-socials li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}
.fxt-template-layout20 ul.fxt-socials li a {
  border-radius: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  height: 45px;
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout20 ul.fxt-socials li a i {
  border-radius: 2px 0 0 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 45px;
  height: 45px;
}
.fxt-template-layout20 ul.fxt-socials li a span {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.fxt-template-layout20 ul.fxt-socials li.fxt-facebook a {
  background-color: #3b5998;
}
.fxt-template-layout20 ul.fxt-socials li.fxt-facebook a i {
  background-color: #4867aa;
}
.fxt-template-layout20 ul.fxt-socials li.fxt-facebook a:hover {
  background-color: #5676bb;
}
.fxt-template-layout20 ul.fxt-socials li.fxt-twitter a {
  background-color: #00acee;
}
.fxt-template-layout20 ul.fxt-socials li.fxt-twitter a i {
  background-color: #33ccff;
}
.fxt-template-layout20 ul.fxt-socials li.fxt-twitter a:hover {
  background-color: #3dc5f3;
}
.fxt-template-layout20 ul.fxt-socials li.fxt-google a {
  background-color: #CC3333;
}
.fxt-template-layout20 ul.fxt-socials li.fxt-google a i {
  background-color: #db4437;
}
.fxt-template-layout20 ul.fxt-socials li.fxt-google a:hover {
  background-color: #e75042;
}
.fxt-template-layout20 .checkbox {
  padding-left: 5px;
}
.fxt-template-layout20 .checkbox label {
  padding-left: 20px;
  color: #a4a4a4;
  margin-bottom: 0;
  font-size: 15px;
  position: relative;
}
.fxt-template-layout20 .checkbox label:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  top: 4px;
  left: 0;
  margin-left: -5px;
  border: 1px solid;
  border-color: #dcdcdc;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.fxt-template-layout20 .checkbox label:after {
  position: absolute;
  margin-left: -20px;
  padding-left: 3px;
  font-size: 10px;
  color: #555555;
}
.fxt-template-layout20 .checkbox input[type="checkbox"] {
  display: none;
}
.fxt-template-layout20 .checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'Font Awesome 5 Free';
  content: "\f00c";
  font-weight: 900;
  color: #ffffff;
  left: 15px;
  top: 4px;
}
.fxt-template-layout20 .checkbox input[type="checkbox"]:checked + label::before {
  background-color: #6b79fd;
  border-color: #6b79fd;
}
.fxt-template-layout20 .fxt-footer {
  text-align: center;
}
/*========================================================================
24. Demo Layout Twenty One
=========================================================================*/
.fxt-template-layout21 {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  background-color: #fff;
  padding: 15px;
}
.fxt-template-layout21 #particles-js {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  opacity: 0.5;
}
.fxt-template-layout21 .fxt-checkbox-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.fxt-template-layout21 .fxt-bg-color {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  -webkit-box-shadow: 0px 0px 62px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 62px 0px rgba(0, 0, 0, 0.07);
}
.fxt-template-layout21 .fxt-bg-img {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding: 0;
}
.fxt-template-layout21 .fxt-content {
  padding: 65px 90px 45px;
  width: 100%;
  background-color: #fff;
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout21 .fxt-content {
    padding: 65px 30px 45px;
  }
}
.fxt-template-layout21 .fxt-header {
  text-align: center;
  margin-bottom: 50px;
}
.fxt-template-layout21 .fxt-header .fxt-logo {
  display: block;
  margin-bottom: 7px;
}
.fxt-template-layout21 .fxt-header h1 {
  color: #fff;
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 10px;
}
.fxt-template-layout21 .fxt-header p {
  color: #999898;
}
.fxt-template-layout21 .fxt-form h2 {
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 5px;
}
.fxt-template-layout21 .fxt-form p {
  font-size: 18px;
  color: #111111;
  text-align: center;
}
.fxt-template-layout21 .fxt-form form .input-label {
  color: #979696;
}
.fxt-template-layout21 .fxt-form .form-group {
  position: relative;
  z-index: 1;
}
.fxt-template-layout21 .fxt-form .form-group .field-icon {
  position: absolute;
  z-index: 1;
  right: 19px;
  bottom: 18px;
  font-size: 14px;
  color: #a1a1a1;
}
.fxt-template-layout21 .fxt-form .form-group .field-icon:before {
  padding: 17px 10px;
}
.fxt-template-layout21 .fxt-form .form-control {
  min-height: 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #e7e7e7;
  padding: 10px 15px;
  color: #111;
}
.fxt-template-layout21 .fxt-form input::-webkit-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout21 .fxt-form input::-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout21 .fxt-form input:-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout21 .fxt-form input:-ms-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout21 .fxt-btn-fill {
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 0;
  color: #fff;
  border-radius: 3px;
  background-color: #111;
  padding: 10px 36px;
  margin-bottom: 10px;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout21 .fxt-btn-fill:hover {
  background-color: #333;
  border-color: #333;
}
.fxt-template-layout21 .fxt-btn-fill:focus {
  outline: none;
}
.fxt-template-layout21 .switcher-text {
  color: #9f9f9f;
  font-size: 15px;
  margin-top: 5px;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout21 .switcher-text:last-child {
  margin-right: 0;
}
.fxt-template-layout21 .switcher-text:hover {
  color: #666;
}
.fxt-template-layout21 .switcher-text.active {
  color: #666;
}
.fxt-template-layout21 .switcher-text2 {
  color: #353535;
  font-size: 15px;
  margin-top: 5px;
  margin-left: 2px;
  display: inline-block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout21 .switcher-text2:last-child {
  margin-right: 0;
}
.fxt-template-layout21 .switcher-text2:hover {
  color: #000;
}
.fxt-template-layout21 .switcher-text2.active {
  color: #000;
}
.fxt-template-layout21 .fxt-style-line {
  overflow: hidden;
  text-align: center;
}
.fxt-template-layout21 .fxt-style-line h3 {
  text-align: center;
  font-weight: 300;
  margin-bottom: 30px;
  font-size: 20px;
  color: #a4a4a4;
  display: inline-block;
  position: relative;
  padding: 0 25px;
  z-index: 1;
}
.fxt-template-layout21 .fxt-style-line h3:before {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #ebebeb;
  left: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.fxt-template-layout21 .fxt-style-line h3:after {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #ebebeb;
  right: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.fxt-template-layout21 ul.fxt-socials {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: 20px;
}
.fxt-template-layout21 ul.fxt-socials li {
  max-width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout21 ul.fxt-socials li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
}
@media only screen and (max-width: 350px) {
  .fxt-template-layout21 ul.fxt-socials li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}
.fxt-template-layout21 ul.fxt-socials li a {
  border-radius: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  height: 45px;
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout21 ul.fxt-socials li a i {
  border-radius: 2px 0 0 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 45px;
  height: 45px;
}
.fxt-template-layout21 ul.fxt-socials li a span {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.fxt-template-layout21 ul.fxt-socials li.fxt-facebook a {
  background-color: #3b5998;
}
.fxt-template-layout21 ul.fxt-socials li.fxt-facebook a i {
  background-color: #4867aa;
}
.fxt-template-layout21 ul.fxt-socials li.fxt-facebook a:hover {
  background-color: #5676bb;
}
.fxt-template-layout21 ul.fxt-socials li.fxt-twitter a {
  background-color: #00acee;
}
.fxt-template-layout21 ul.fxt-socials li.fxt-twitter a i {
  background-color: #33ccff;
}
.fxt-template-layout21 ul.fxt-socials li.fxt-twitter a:hover {
  background-color: #3dc5f3;
}
.fxt-template-layout21 ul.fxt-socials li.fxt-google a {
  background-color: #CC3333;
}
.fxt-template-layout21 ul.fxt-socials li.fxt-google a i {
  background-color: #db4437;
}
.fxt-template-layout21 ul.fxt-socials li.fxt-google a:hover {
  background-color: #e75042;
}
.fxt-template-layout21 .checkbox {
  padding-left: 5px;
}
.fxt-template-layout21 .checkbox label {
  padding-left: 20px;
  color: #a4a4a4;
  margin-bottom: 0;
  font-size: 15px;
  position: relative;
}
.fxt-template-layout21 .checkbox label:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  top: 4px;
  left: 0;
  margin-left: -5px;
  border: 1px solid;
  border-color: #dcdcdc;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.fxt-template-layout21 .checkbox label:after {
  position: absolute;
  margin-left: -20px;
  padding-left: 3px;
  font-size: 10px;
  color: #555555;
}
.fxt-template-layout21 .checkbox input[type="checkbox"] {
  display: none;
}
.fxt-template-layout21 .checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'Font Awesome 5 Free';
  content: "\f00c";
  font-weight: 900;
  color: #ffffff;
  left: 15px;
  top: 4px;
}
.fxt-template-layout21 .checkbox input[type="checkbox"]:checked + label::before {
  background-color: #111;
  border-color: #111;
}
.fxt-template-layout21 .fxt-footer {
  text-align: center;
}
.fxt-template-layout21 .fxt-footer p {
  color: #999898;
}
/*========================================================================
25. Demo Layout Twenty Two
=========================================================================*/
.fxt-template-layout22 {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  z-index: 1;
  padding: 15px;
}
.fxt-template-layout22:before {
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(4, 4, 14, 0.5);
  left: 0;
  top: 0;
  position: absolute;
  z-index: 0;
}
.fxt-template-layout22 .fxt-checkbox-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 40px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.fxt-template-layout22 .fxt-bg-color {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
}
.fxt-template-layout22 .fxt-bg-img {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding: 0;
}
.fxt-template-layout22 .fxt-content {
  padding: 65px 90px 45px;
  width: 100%;
  background-color: #fff;
}
@media only screen and (max-width: 1199px) {
  .fxt-template-layout22 .fxt-content {
    padding: 65px 70px 45px;
  }
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout22 .fxt-content {
    padding: 50px 30px 30px;
  }
}
.fxt-template-layout22 .fxt-header {
  padding: 30px 30px 30px 0;
}
.fxt-template-layout22 .fxt-header .fxt-logo {
  display: block;
  margin-bottom: 50px;
  max-width: 40vw;
}
.fxt-template-layout22 .fxt-header h1 {
  color: #fff;
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 10px;
}
.fxt-template-layout22 .fxt-header p {
  color: #fff;
}
.fxt-template-layout22 .fxt-form h2 {
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 5px;
}
.fxt-template-layout22 .fxt-form p {
  font-size: 18px;
  color: #111111;
  text-align: center;
}
.fxt-template-layout22 .fxt-form form {
  margin-top: 40px;
}
.fxt-template-layout22 .fxt-form form .input-label {
  color: #979696;
}
.fxt-template-layout22 .fxt-form .form-group {
  position: relative;
  z-index: 1;
}
.fxt-template-layout22 .fxt-form .form-group .field-icon {
  position: absolute;
  z-index: 1;
  right: 24px;
  bottom: 18px;
  padding: 0 5px;
  color: #c5c5c5;
  font-size: 14px;
}
.fxt-template-layout22 .fxt-form .form-group .field-icon:before {
  padding: 17px 10px;
}
.fxt-template-layout22 .fxt-form .form-control {
  min-height: 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #e7e7e7;
  padding: 10px 15px;
  color: #111111;
}
.fxt-template-layout22 .fxt-form input::-webkit-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout22 .fxt-form input::-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout22 .fxt-form input:-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout22 .fxt-form input:-ms-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.fxt-template-layout22 .fxt-btn-fill {
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 0;
  color: #fff;
  border-radius: 3px;
  background-color: #ff0000;
  padding: 10px 36px;
  margin-bottom: 10px;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout22 .fxt-btn-fill:hover {
  background-color: #da0101;
  border-color: #da0101;
}
.fxt-template-layout22 .fxt-btn-fill:focus {
  outline: none;
}
.fxt-template-layout22 .switcher-text {
  color: #55d1ff;
  font-size: 15px;
  margin-left: 3px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout22 .switcher-text:last-child {
  margin-right: 0;
}
.fxt-template-layout22 .switcher-text:hover {
  color: #666;
}
.fxt-template-layout22 .switcher-text.active {
  color: #666;
}
.fxt-template-layout22 .fxt-style-line {
  overflow: hidden;
  text-align: center;
}
.fxt-template-layout22 .fxt-style-line h3 {
  text-align: center;
  font-weight: 300;
  margin-bottom: 30px;
  font-size: 20px;
  color: #a4a4a4;
  display: inline-block;
  position: relative;
  padding: 0 25px;
  z-index: 1;
}
.fxt-template-layout22 .fxt-style-line h3:before {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #ebebeb;
  left: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.fxt-template-layout22 .fxt-style-line h3:after {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #ebebeb;
  right: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.fxt-template-layout22 ul.fxt-socials {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: 20px;
}
.fxt-template-layout22 ul.fxt-socials li {
  display: inline-block;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout22 ul.fxt-socials li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.fxt-template-layout22 ul.fxt-socials li a {
  border-radius: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  height: 45px;
  width: 100%;
  color: #ffffff;
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout22 ul.fxt-socials li a:before {
  content: "";
  width: 0;
  right: 0;
  left: inherit;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout22 ul.fxt-socials li a:hover:before {
  width: 100%;
  left: 0;
  right: inherit;
}
.fxt-template-layout22 ul.fxt-socials li.fxt-facebook a {
  background-color: #3b5998;
}
.fxt-template-layout22 ul.fxt-socials li.fxt-facebook a:before {
  background-color: #5676bb;
}
.fxt-template-layout22 ul.fxt-socials li.fxt-twitter a {
  background-color: #00acee;
}
.fxt-template-layout22 ul.fxt-socials li.fxt-twitter a:before {
  background-color: #3dc5f3;
}
.fxt-template-layout22 ul.fxt-socials li.fxt-google a {
  background-color: #CC3333;
}
.fxt-template-layout22 ul.fxt-socials li.fxt-google a:before {
  background-color: #e75042;
}
.fxt-template-layout22 ul.fxt-socials li.fxt-linkedin a {
  background-color: #0077B5;
}
.fxt-template-layout22 ul.fxt-socials li.fxt-linkedin a:before {
  background-color: #006da6;
}
.fxt-template-layout22 .checkbox {
  padding-left: 5px;
}
.fxt-template-layout22 .checkbox label {
  padding-left: 20px;
  color: #a4a4a4;
  margin-bottom: 0;
  font-size: 15px;
  position: relative;
}
.fxt-template-layout22 .checkbox label:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  top: 4px;
  left: 0;
  margin-left: -5px;
  border: 1px solid;
  border-color: #dcdcdc;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.fxt-template-layout22 .checkbox label:after {
  position: absolute;
  margin-left: -20px;
  padding-left: 3px;
  font-size: 10px;
  color: #555555;
}
.fxt-template-layout22 .checkbox input[type="checkbox"] {
  display: none;
}
.fxt-template-layout22 .checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'Font Awesome 5 Free';
  content: "\f00c";
  font-weight: 900;
  color: #ffffff;
  left: 15px;
  top: 4px;
}
.fxt-template-layout22 .checkbox input[type="checkbox"]:checked + label::before {
  background-color: #ff0000;
  border-color: #ff0000;
}
.fxt-template-layout22 .fxt-footer {
  text-align: center;
}
/*========================================================================
26. Demo Layout Twenty Three
=========================================================================*/
.fxt-template-layout23 {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: #011f42;
}
.fxt-template-layout23 .fxt-bg-overlay {
  min-height: 100vh;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: flex-start;
  padding: 60px 30px;
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout23 .fxt-bg-overlay {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
.fxt-template-layout23 .fxt-content {
  max-width: 460px;
  width: 100%;
  margin-left: 15vw;
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout23 .fxt-content {
    margin-left: 0;
  }
}
.fxt-template-layout23 .fxt-header {
  text-align: center;
  margin-bottom: 50px;
}
.fxt-template-layout23 .fxt-logo {
  display: block;
  margin-bottom: 100px;
  margin-left: auto;
  margin-right: auto;
  max-width: 40vw;
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout23 .fxt-logo {
    margin-bottom: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout23 .fxt-logo {
    margin-bottom: 60px;
  }
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout23 .fxt-logo {
    margin-bottom: 40px;
  }
}
.fxt-template-layout23 .fxt-form p {
  font-size: 20px;
  color: #fff;
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout23 .fxt-form p {
    text-align: center;
  }
}
.fxt-template-layout23 .fxt-form .form-group {
  position: relative;
  z-index: 1;
}
.fxt-template-layout23 .fxt-form .form-group .field-icon {
  position: absolute;
  z-index: 1;
  right: 19px;
  bottom: 18px;
  font-size: 14px;
  color: #bebebe;
}
.fxt-template-layout23 .fxt-form .form-group .field-icon:before {
  padding: 17px 10px;
}
.fxt-template-layout23 .fxt-form .form-control {
  min-height: 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid rgba(241, 241, 241, 0.25);
  padding: 10px 15px;
  background-color: transparent;
  color: #fff;
}
.fxt-template-layout23 .fxt-form input::-webkit-input-placeholder {
  color: #bebebe;
  font-size: 18px;
  font-weight: 300;
}
.fxt-template-layout23 .fxt-form input::-moz-placeholder {
  color: #bebebe;
  font-size: 18px;
  font-weight: 300;
}
.fxt-template-layout23 .fxt-form input:-moz-placeholder {
  color: #bebebe;
  font-size: 18px;
  font-weight: 300;
}
.fxt-template-layout23 .fxt-form input:-ms-input-placeholder {
  color: #bebebe;
  font-size: 18px;
  font-weight: 300;
}
.fxt-template-layout23 .fxt-btn-fill {
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 0;
  color: #fff;
  border-radius: 3px;
  background-color: #1fbe66;
  padding: 10px 36px;
  margin-bottom: 10px;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout23 .fxt-btn-fill:hover {
  background-color: #17a156;
  border-color: #17a156;
}
.fxt-template-layout23 .fxt-btn-fill:focus {
  outline: none;
}
.fxt-template-layout23 .switcher-text {
  color: #b6b6b6;
  font-size: 15px;
  margin-top: 5px;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout23 .switcher-text:last-child {
  margin-right: 0;
}
.fxt-template-layout23 .switcher-text:hover {
  color: #e6e6e6;
}
.fxt-template-layout23 .switcher-text2 {
  color: #d4d4d4;
  font-size: 15px;
  margin-top: 5px;
  margin-left: 2px;
  display: inline-block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout23 .switcher-text2:last-child {
  margin-right: 0;
}
.fxt-template-layout23 .switcher-text2:hover {
  color: #e6e6e6;
}
.fxt-template-layout23 .fxt-style-line {
  overflow: hidden;
  text-align: center;
}
.fxt-template-layout23 .fxt-style-line h3 {
  text-align: center;
  font-weight: 300;
  margin-bottom: 30px;
  font-size: 20px;
  color: #a4a4a4;
  display: inline-block;
  position: relative;
  padding: 0 25px;
  z-index: 1;
}
.fxt-template-layout23 .fxt-style-line h3:before {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #a4a4a4;
  left: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.fxt-template-layout23 .fxt-style-line h3:after {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #a4a4a4;
  right: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.fxt-template-layout23 ul.fxt-socials {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: 20px;
}
.fxt-template-layout23 ul.fxt-socials li {
  max-width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 575px) {
  .fxt-template-layout23 ul.fxt-socials li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
}
@media only screen and (max-width: 350px) {
  .fxt-template-layout23 ul.fxt-socials li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}
.fxt-template-layout23 ul.fxt-socials li a {
  border-radius: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  height: 45px;
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout23 ul.fxt-socials li a i {
  border-radius: 2px 0 0 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 45px;
  height: 45px;
}
.fxt-template-layout23 ul.fxt-socials li a span {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.fxt-template-layout23 ul.fxt-socials li.fxt-facebook a {
  background-color: #3b5998;
}
.fxt-template-layout23 ul.fxt-socials li.fxt-facebook a i {
  background-color: #4867aa;
}
.fxt-template-layout23 ul.fxt-socials li.fxt-facebook a:hover {
  background-color: #5676bb;
}
.fxt-template-layout23 ul.fxt-socials li.fxt-twitter a {
  background-color: #00acee;
}
.fxt-template-layout23 ul.fxt-socials li.fxt-twitter a i {
  background-color: #33ccff;
}
.fxt-template-layout23 ul.fxt-socials li.fxt-twitter a:hover {
  background-color: #3dc5f3;
}
.fxt-template-layout23 ul.fxt-socials li.fxt-google a {
  background-color: #CC3333;
}
.fxt-template-layout23 ul.fxt-socials li.fxt-google a i {
  background-color: #db4437;
}
.fxt-template-layout23 ul.fxt-socials li.fxt-google a:hover {
  background-color: #e75042;
}
.fxt-template-layout23 .fxt-checkbox-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.fxt-template-layout23 .checkbox {
  padding-left: 5px;
  margin-right: 30px;
}
.fxt-template-layout23 .checkbox label {
  padding-left: 20px;
  color: #b9b9b9;
  margin-bottom: 0;
  font-size: 15px;
  position: relative;
}
.fxt-template-layout23 .checkbox label:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  top: 4px;
  left: 0;
  margin-left: -5px;
  border: 1px solid;
  border-color: #dcdcdc;
  border-radius: 2px;
  background-color: transparent;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.fxt-template-layout23 .checkbox label:after {
  position: absolute;
  margin-left: -20px;
  padding-left: 3px;
  font-size: 10px;
  color: #555555;
}
.fxt-template-layout23 .checkbox input[type="checkbox"] {
  display: none;
}
.fxt-template-layout23 .checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'Font Awesome 5 Free';
  content: "\f00c";
  font-weight: 900;
  color: #ffffff;
  left: 15px;
  top: 4px;
}
.fxt-template-layout23 .checkbox input[type="checkbox"]:checked + label::before {
  background-color: #1fbe66;
  border-color: #1fbe66;
}
.fxt-template-layout23 .fxt-footer {
  text-align: center;
}
.fxt-template-layout23 .fxt-footer p {
  color: #b6b6b6;
}
/*========================================================================
27. Demo Layout Twenty Four
=========================================================================*/
.fxt-template-layout24 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  position: relative;
  min-height: 100vh;
  z-index: 1;
  padding: 50px 0 20px;
}
.fxt-template-layout24 .fxt-video-background {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
}
.fxt-template-layout24 .fxt-video-background:before {
  content: "";
  position: absolute;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.fxt-template-layout24 .fxt-video-background .fxt-video {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
}
.fxt-template-layout24 .fxt-checkbox-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.fxt-template-layout24 .fxt-content {
  padding-top: 40px;
  padding-bottom: 15px;
  padding-left: 70px;
  margin-left: 40px;
  border-left: 2px solid rgba(241, 241, 241, 0.15);
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout24 .fxt-content {
    padding-left: 0;
    margin-left: 0;
    margin-top: 40px;
    border-left: 0;
    padding-bottom: 0;
    border-top: 2px solid rgba(241, 241, 241, 0.15);
  }
}
.fxt-template-layout24 .fxt-content h2 {
  font-size: 20px;
  color: #fff;
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout24 .fxt-content h2 {
    text-align: center;
  }
}
.fxt-template-layout24 .fxt-header {
  text-align: center;
}
.fxt-template-layout24 .fxt-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 40vw;
}
.fxt-template-layout24 .fxt-form .form-group {
  position: relative;
  z-index: 1;
}
.fxt-template-layout24 .fxt-form .form-group .field-icon {
  position: absolute;
  z-index: 1;
  right: 19px;
  bottom: 18px;
  font-size: 14px;
  color: #bebebe;
}
.fxt-template-layout24 .fxt-form .form-group .field-icon:before {
  padding: 17px 10px;
}
.fxt-template-layout24 .fxt-form .form-control {
  min-height: 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid rgba(241, 241, 241, 0.25);
  padding: 10px 15px;
  background-color: transparent;
  color: #fff;
}
.fxt-template-layout24 .fxt-form input::-webkit-input-placeholder {
  color: #bebebe;
  font-size: 18px;
  font-weight: 300;
}
.fxt-template-layout24 .fxt-form input::-moz-placeholder {
  color: #bebebe;
  font-size: 18px;
  font-weight: 300;
}
.fxt-template-layout24 .fxt-form input:-moz-placeholder {
  color: #bebebe;
  font-size: 18px;
  font-weight: 300;
}
.fxt-template-layout24 .fxt-form input:-ms-input-placeholder {
  color: #bebebe;
  font-size: 18px;
  font-weight: 300;
}
.fxt-template-layout24 .fxt-btn-fill {
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 0;
  color: #fff;
  border-radius: 3px;
  background-color: #ffbd3f;
  padding: 10px 36px;
  margin-bottom: 10px;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout24 .fxt-btn-fill:hover {
  background-color: #db9e2d;
  border-color: #db9e2d;
}
.fxt-template-layout24 .fxt-btn-fill:focus {
  outline: none;
}
.fxt-template-layout24 .switcher-text {
  color: #b6b6b6;
  font-size: 15px;
  margin-top: 5px;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout24 .switcher-text:last-child {
  margin-right: 0;
}
.fxt-template-layout24 .switcher-text:hover {
  color: #e6e6e6;
}
.fxt-template-layout24 .switcher-text2 {
  color: #d4d4d4;
  font-size: 15px;
  margin-top: 5px;
  margin-left: 2px;
  display: inline-block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout24 .switcher-text2:last-child {
  margin-right: 0;
}
.fxt-template-layout24 .switcher-text2:hover {
  color: #e6e6e6;
}
.fxt-template-layout24 .checkbox {
  padding-left: 5px;
  margin-right: 10px;
}
.fxt-template-layout24 .checkbox label {
  padding-left: 20px;
  color: #b9b9b9;
  margin-bottom: 0;
  font-size: 15px;
  position: relative;
}
.fxt-template-layout24 .checkbox label:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  top: 4px;
  left: 0;
  margin-left: -5px;
  border: 1px solid;
  border-color: #dcdcdc;
  border-radius: 2px;
  background-color: transparent;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.fxt-template-layout24 .checkbox label:after {
  position: absolute;
  margin-left: -20px;
  padding-left: 3px;
  font-size: 10px;
  color: #555555;
}
.fxt-template-layout24 .checkbox input[type="checkbox"] {
  display: none;
}
.fxt-template-layout24 .checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'Font Awesome 5 Free';
  content: "\f00c";
  font-weight: 900;
  color: #ffffff;
  left: 15px;
  top: 4px;
}
.fxt-template-layout24 .checkbox input[type="checkbox"]:checked + label::before {
  background-color: #ffbd3f;
  border-color: #ffbd3f;
}
.fxt-template-layout24 .fxt-footer {
  text-align: center;
}
.fxt-template-layout24 .fxt-footer p {
  color: #b6b6b6;
}
.paginacao:hover {
  background-color: #e6e6e6;
}


.content-modal{
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    background: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
}

.content-modal-loading{
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    background: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 9999999;
}

.modal{
    width: 40%;
    height: auto;
    background-color: #fff;
    border-radius: 5px;
    display: block;
    position: relative;
    margin-top: 2%;
    margin-bottom: 2%;
}

.modal-logs{
    width: 60%;
    height: auto;
    background-color: #fff;
    border-radius: 5px;
    display: block;
    position: relative;
    margin-top: 2%;
    margin-bottom: 2%;
}

.highest-modal{
  width: 80%;
  height: auto;
  background-color: #fff;
  border-radius: 5px;
  display: block;
  position: relative;
  margin-top: 2%;
  margin-bottom: 2%;
}

.modal-head{
    padding: 15px 20px;
    font-size: 1.3em;
    font-weight: 700;
    color: #000;
    text-align: left;
}

ul a li:hover {
  background-color: #EDE7FB;
  color: #430BBA;
  font-weight: 600;
  border-radius: 0px 5px 5px 0px;
}

.modal-body{
    width: 100%;
    padding: 10px 5px;
    font-size: 1em;
    color: #444;
}

.modal-scroll{
    max-height: 70vh;
    overflow-y: auto;
    padding: 0px 15px;
}

.modal-scroll-logs{
    max-height: 70vh;
    overflow-y: auto;
    padding: 0px 15px;
    word-break: break-all;
}

.modal-scroll::-webkit-scrollbar{
  width: 6px;
}

.modal-scroll::-webkit-scrollbar-track{
  background-color: var(--color-elements-white-dark);
}

.modal-scroll::-webkit-scrollbar-thumb{
  border-radius: 6px;
  background-color: #c5c5c5;
}

.modal-scroll::-webkit-scrollbar-thumb:hover{
  background-color:#000;
}

.modal-foot{
    margin-top: 1.5%;
    width: 100%;
    padding: 0px 20px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.modal-foot button{
  padding: 0.5% 2%;
  border: none;
  border-radius: 2.5px;
  outline: none;
  cursor: pointer;
  margin: 1%;
  width: 92px;
}

.modal-foot .modal-fechar{
  background-color: #000000;
  color: #fff;
}

.modal-foot .modal-salvar{
  background-color: #ffffff;
  color: #000;
}

.botao_dashboard{
  padding: 0.3vw 2vw;
  margin: 0.8vw;
  border-radius: 5px;
  border: 1px solid #6b24d6;
  color: #6b24d6;
  background-color: transparent;
  transition: 0.2s;
}

.botao_dashboard:hover{
  color: #ffffff;
  background-color: #6b24d6;
}

.demonstrativo{
  top: 0;
  left: 0;
  padding: 0 !important;
  margin: 0 !important;
  box-sizing: border-box !important;
  font-family: 'Dosis', sans-serif !important;
  width: 100% !important;
  height: 100% !important;
  color: #76777C !important;
  position: absolute;
  background-color: white;
  z-index: 2;
}

.relatorio_inadimplentes{
  top: 0;
  left: 0;
  padding: 0 !important;
  margin: 0 !important;
  box-sizing: border-box !important;
  font-family: 'Dosis', sans-serif !important;
  width: 100% !important;
  height: 100% !important;
  color: #76777C !important;
  position: absolute;
  background-color: white;
  z-index: 2;
}

.demonstrativoDimob{
  top: 0;
  left: 0;
  padding: 0 !important;
  margin: 0 !important;
  box-sizing: border-box !important;
  font-family: 'Dosis', sans-serif !important;
  width: 100% !important;
  /* height: 100% !important; */
  color: #76777C !important;
  position: absolute;
  background-color: white;
  z-index: 2;
}

.header_demonstrativo{
  position: relative;
}

.header_demonstrativo img{
  height: 30px;
  position: absolute;
  top: 20px;
  left: 0;
}

.docs-head{
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.docs-head h2{
  font-size: 26px;
  text-transform: uppercase;
  font-weight: 400;
}

.docs-head p{
  text-align: center;
  margin: 0px;
  font-size: 1.4em;
}

.docs-head span{
  text-transform: uppercase;
}

.table_demonstrativo thead{
  background-color: #C9C9C9;
  color: #676867;
  font-size: 1.5em;
}

.table_demonstrativo td, .table_demonstrativo th {
  padding: 0px 10px;
  vertical-align: top;
  border-top: 0px;
  border-bottom: 1px solid #dee2e6;
}

.table_demonstrativo thead th {
  vertical-align: bottom;
  border-bottom: 0px solid;
}

.table_demonstrativo td{
  font-size: 14px;
  height: auto;
  padding: 3px 10px;
  vertical-align: top;
  border-top: 1px solid #dee2e6 !important;
  color: #212529;
  background-color: #FFFFFF;
}

.table_relatorio td{
  font-size: 14px;
  height: auto;
  padding: 3px 10px;
  vertical-align: top;
  border-top: 1px solid #dee2e6 !important;
  color: #212529;
  background-color: #FFFFFF;
}

.table_relatorio tr{
  background-color: #0070c0 !important;
}

.box-paginacao{
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


tr.stripedColor:nth-child(even){
  background-color: #eee;
}

.loader__back.active{
  display: flex;
}

.loader__back{
  display: none;
  z-index: 900;
  background-color: rgba(0, 0, 0, 0.40);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.loader__rolling{
  background-color: white;
  padding: 3rem;
  border-radius: 6px;
  box-sizing: border-box;
}

.loader{
  border-radius: 100%;
  width: 13rem;
  height: 13rem;
  box-sizing: border-box;
  border: 6px solid rgb(253, 253, 253);
  border-left-color: rgba(112,33,213,1);
  animation: rolling 1s infinite linear;
}

@keyframes rolling{
  from{
    transform: rotate(0deg);
  }

  to{
    transform: rotate(360deg)
  }
}

.checkbox-label {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  line-height: 20px;
  height: 20px;
  width: 20px;
  clear: both;
  margin-right: 10px;
}

.checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-label .checkbox-custom {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 20px;
  width: 20px;
  background-color: #c5c5c5;
  border-radius: 3px;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  border: 2px solid var(#c5c5c5);
}

.checkbox-label input:checked ~ .checkbox-custom {
  background-color: #430BBA;
  border-radius: 3px;
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  opacity:1;
  border: 2px solid #430BBA;
}

.checkbox-label .checkbox-custom::after {
  position: absolute;
  content: "";
  left: 12px;
  top: 12px;
  height: 0px;
  width: 0px;
  border-radius: 2px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(0deg) scale(0);
  -ms-transform: rotate(0deg) scale(0);
  transform: rotate(0deg) scale(0);
  opacity:1;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}

.checkbox-label input:checked ~ .checkbox-custom::after {
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  opacity: 1;
  left: 6px;
  top: 3px;
  width: 4px;
  height: 8px;
  border: solid #fff;
  border-width: 0 2.5px 2.5px 0;
  background-color: transparent;
  border-radius: 0;
}

.swal2-popup {
  font-size: 1em !important;
}